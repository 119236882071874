import React, { useCallback, useEffect, useState } from 'react'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import CompletedCandidateCell from '../../components/Candidate/CompletedCandidateCell'
import '../../styles/pages/candidates.less'
import { Button, Col, Form, Pagination, Popover, Radio, Row, Select, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import { selectCandidates } from '../../store/candidates/candidates.selector'
import { searchCandidates } from '../../store/candidates/candidates.actions'
import { clearActionResult } from '../../store/app/app.actions'
import { actionTypes } from '../../store/candidates/candidates.types'
import { selectBranchUsers } from '../../store/users/users.selectors'
import { getBranchUsers } from '../../store/users/users.actions'
import { FilterOutlined, UserOutlined } from '@ant-design/icons'
import {
  selectCurrentBranch,
  selectCurrentCompany,
  selectIsAdmin,
  selectIsSupervisor
} from '../../store/auth/auth.selectors'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { useIsLoading } from '../../store/app/loading/loading.hooks'

const { Option } = Select

const sortProperties = [
  { label: 'Folio', property: 'id' },
  { label: 'Nombre', property: 'name' },
  { label: 'Fecha de Entrega', property: 'finishDate' }
]

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginRight: '30px'
}

const CompletedCandidates = () => {
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm()
  const [filter, setFilter] = useState(0)
  const [user, setUser] = useState(0)
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const candidates = useSelector(selectCandidates)
  const data = candidates?.items || []
  const branchUsers = useSelector(selectBranchUsers)
  const isSupervisor = useSelector(selectIsSupervisor)
  const isAdmin = useSelector(selectIsAdmin)
  const access = isSupervisor || isAdmin
  const [loading] = useIsLoading([actionTypes.SEARCH_CANDIDATES, actionTypes.DOWNLOAD_REPORT])

  const refresh = useCallback(({ search, last, from, to, sortOrder, sortProperty } = {}) => {
    dispatch(searchCandidates({
      status: 'TERMINADO',
      search,
      from: from?.unix(),
      to: to?.unix(),
      last,
      result: filter,
      ...pagination,
      sortOrder,
      sortProperty,
      byUser: access ? user : null
    }))
  }, [access, dispatch, filter, pagination, user])

  const currentCompany = useSelector(selectCurrentCompany)
  const currentBranch = useSelector(selectCurrentBranch)
  useEffect(refresh, [currentCompany, currentBranch])

  const init = () => {
    dispatch(updateTopBarTitle('Candidatos Completados'))
    dispatch(searchCandidates({ status: 'TERMINADO' }))
    if (isSupervisor) {
      dispatch(getBranchUsers())
    }
    return () => dispatch(clearActionResult(actionTypes.SEARCH_CANDIDATES))
  }
  useEffect(init, [])

  const onPageChange = (page, pageSize) => setPagination({ page, pageSize })

  const getFilters = () => {
    return (
      <Row className='gx-p-2'>
        <Col>
          <Radio.Group onChange={e => setFilter(e.target.value)} value={filter}>
            <Radio value={0} style={radioStyle}>Todos</Radio>
            <Radio value={1} style={radioStyle}>Aptos</Radio>
            <Radio value={2} style={radioStyle}>Aptos C/R</Radio>
            <Radio value={3} style={radioStyle}>No Aptos</Radio>
          </Radio.Group>
        </Col>
      </Row>
    )
  }

  const getUserFilters = () => {
    return (
      <Row className='gx-p-2'>
        <Col>
          <Select style={{ width: 200 }} showSearch optionFilterProp='children' value={user} onChange={setUser}>
            <Option value={0}>Todos</Option>
            {
              (branchUsers || []).map((user) =>
                <Option key={`users-${user.id}`} value={user.id}>{user.name}</Option>
              )
            }
          </Select>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={loading} />
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <CustomSearch form={searchForm} onChange={refresh} sortProperties={sortProperties} />
              <div style={{ borderLeft: '1px solid grey', height: 35, marginLeft: 30, marginRight: 5, marginTop: -15 }} />
              <Row>
                <Tooltip title='Filtrar por resultado'>
                  <Popover content={getFilters()} title='Filtrar por estado' trigger='click' placement='bottomRight'>
                    <Button icon={<FilterOutlined />} />
                  </Popover>
                </Tooltip>
              </Row>
              {
                access &&
                  <Row justify='end' style={{ marginLeft: 25 }}>
                    <Tooltip title='Filtrar por usuario'>
                      <Popover content={getUserFilters()} title='Filtrar por usuario' trigger='click' placement='bottomRight'>
                        <Button icon={<UserOutlined />} />
                      </Popover>
                    </Tooltip>
                  </Row>
              }
              <span style={{ paddingRight: 30 }} />
            </div>
            <CustomScrollbars className='completed-scroll'>
              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      No se encontraron candidatos con los filtros específicados
                    </div>
                  )
                  : (
                    <div>
                      {data.map((candidate, index) =>
                        <CompletedCandidateCell candidate={candidate} key={index} />
                      )}
                      <div style={{ height: '1em' }} />
                    </div>
                  )
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={candidates.total || 0}
                current={candidates.page || 0}
                pageSize={candidates.pageSize || 0}
                hideOnSinglePage={candidates.total === 0}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompletedCandidates
