import UsersService from './users.service'
import { executeAction } from '../app/app.actions'
import { actionTypes } from './users.types'

export const getBranchUsers = () => dispatch => {
  const process = () => UsersService.getBranchUsers()
  dispatch(executeAction(actionTypes.GET_BRANCH_USERS, process))
}

export const createUserWithEmailAndName = ({ email, name }) => dispatch => {
  const process = () => UsersService.createUserWithEmailAndName({ email, name })
  dispatch(executeAction(actionTypes.ADD_USER, process))
}

export const setCurrentUser = ({ user }) => dispatch => {
  dispatch(executeAction(actionTypes.ADD_USER, user))
}

export const toggleAdmin = ({ userId, prev }) => dispatch => {
  const process = async () => {
    if (prev) {
      return await UsersService.unsetUserAdmin({ userId })
    }

    return await UsersService.setUserAdmin({ userId })
  }
  dispatch(executeAction(actionTypes.TOGGLE_ADMIN, process))
}

export const toggleOperator = ({ userId, prev }) => dispatch => {
  const process = async () => {
    if (prev) {
      return await UsersService.unsetUserOperator({ userId })
    }

    return await UsersService.setUserOperator({ userId })
  }
  dispatch(executeAction(actionTypes.TOGGLE_OPERATOR, process))
}

export const toggleSupervision = ({ userId, prev }) => dispatch => {
  const process = async () => {
    if (prev) {
      return await UsersService.unsetUserSupervisor({ userId })
    }

    return await UsersService.setUserSupervisor({ userId })
  }
  dispatch(executeAction(actionTypes.TOGGLE_SUPERVISOR, process))
}

export const deleteUser = ({ userId }) => dispatch => {
  const process = async () => {
    const resultSupervisor = await UsersService.unsetUserSupervisor({ userId })
    const resultOperator = await UsersService.unsetUserOperator({ userId })

    return resultOperator || resultSupervisor
  }

  dispatch(executeAction(actionTypes.DELETE_USER, process))
}

export const createUser = ({ email, name, isOperator, isSupervisor }) => dispatch => {
  const process = async () => {
    const { data: { exists, user } } = await UsersService.checkUserEmail({ email })
    let result
    let userPassword = ''

    if (!exists && !user) {
      const { data: { user, password } } = await UsersService.createUserWithEmailAndName({ email, name })
      result = user
      userPassword = password
    } else {
      result = user
    }

    let users = []
    if (isOperator) {
      const { data } = await UsersService.setUserOperator({ userId: result?.id })
      users = data
    }

    if (isSupervisor) {
      const { data } = await UsersService.setUserSupervisor({ userId: result?.id })
      users = data
    }

    return { users, password: userPassword }
  }

  dispatch(executeAction(actionTypes.ADD_USER, process))
}

export const resetPassword = ({ userId }) => dispatch => {
  const process = () => UsersService.resetPassword({ userId })
  dispatch(executeAction(actionTypes.RESET_PASSWORD, process))
}

export const removeUserFromCompany = ({ userId }) => dispatch => {
  const process = () => UsersService.removeFromCompany({ userId })
  dispatch(executeAction(actionTypes.REMOVE_FROM_COMPANY, process))
}

export const clearUsers = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_USERS))
}
