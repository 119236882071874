import { executeAction } from '../app/app.actions'
import { actionTypes } from './company.types'
import CompanyService from './company.service'

export const getCompany = () => dispatch => {
  const process = () => CompanyService.getCompany()
  dispatch(executeAction(actionTypes.GET_COMPANY, process))
}

export const updateCompany = ({ address, contactName, email, name, phone }) => dispatch => {
  const process = () => CompanyService.update({ address, contactName, email, name, phone })
  dispatch(executeAction(actionTypes.UPDATE_COMPANY, process))
}

export const clearCompany = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_COMPANY))
}
