import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, Modal, Select } from 'antd'
import { selectCurrentBranch, selectCurrentCompany, selectCurrentUserCompanies } from '../../store/auth/auth.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentBranch, setCurrentCompany } from '../../store/auth/auth.actions'
import { sortIgnoreCase } from '../../util/text.utils'

const { Option } = Select

const CompanySelector = ({ show, onConfirm, cancelable = true }) => {
  const companies = useSelector(selectCurrentUserCompanies)
  const currentCompany = useSelector(selectCurrentCompany)
  const currentBranch = useSelector(selectCurrentBranch)

  const [company, setCompany] = useState(currentCompany)
  const [branch, setBranch] = useState(currentBranch)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const sortedCompanies = useMemo(() => companies.sort((a, b) => (a.name > b.name) ? 1 : -1), [companies])
  const filter = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

  useEffect(() => {
    const selectedCompany = companies.find(c => c.id === currentCompany?.id)
    setCompany(selectedCompany)
  }, [companies, currentCompany])

  useEffect(() => {
    form.setFieldsValue({
      company: currentCompany?.id,
      branch: currentBranch?.id
    })
    setCompany(currentCompany)
    setBranch(currentBranch)
  }, [currentCompany, currentBranch, form])

  const onCompanyChange = (value) => {
    const selectedCompany = companies.find(c => c.id === value)
    setCompany(selectedCompany)
    setBranch(selectedCompany.branches[0])
  }

  const onBranchChange = (value) => {
    const selectedBranch = company.branches.find(b => b.id === value)
    setBranch(selectedBranch)
  }

  const onSelect = () => {
    dispatch(setCurrentCompany(company))
    dispatch(setCurrentBranch(branch))
    onConfirm()
  }

  return (
    <Modal
      centered
      closable={cancelable}
      maskClosable={cancelable}
      keyboard={cancelable}
      width={700}
      forceRender
      title='Selecciona una empresa / sucursal para operar'
      visible={show}
      onCancel={onConfirm}
      footer={[
        cancelable
          ? (
            <Button
              key='Cancelar'
              onClick={onConfirm}
            > Cancelar
            </Button>
          ) : <div key='None' />,
        <Button
          key='Registrar'
          type='primary'
          onClick={onSelect}
          disabled={currentBranch == null && branch == null}
        >
          Cambiar
        </Button>
      ]}
    >
      <div>
        <Form form={form} layout='inline'>
          <Form.Item label='Empresa' name='company' initialValue={company?.id}>
            <Select
              showSearch
              value={company?.id}
              style={{ width: 200 }}
              placeholder='Elija una empresa'
              optionFilterProp='children'
              notFoundContent='Sin datos'
              filterOption={filter}
              onChange={onCompanyChange}
            >
              {
                sortedCompanies.map(company =>
                  <Option key={`company-${company.id}`} value={company.id}>{company.name}</Option>
                )
              }
            </Select>
          </Form.Item>
          {
            company != null && company.branches.length > 0 &&
              <Form.Item label='Sucursal' name='branch' initialValue={branch?.id}>
                <Select
                  showSearch
                  value={branch?.id}
                  style={{ width: 200 }}
                  placeholder='Elija una sucursal'
                  optionFilterProp='children'
                  notFoundContent='Sin datos'
                  filterOption={filter}
                  onChange={onBranchChange}
                >

                  {
                    company.branches.sort((a, b) => sortIgnoreCase(a.name, b.name)).map((branch, index) =>
                      <Option key={`branch-${branch.id}-${index}`} value={branch.id}>{branch.name}</Option>
                    )
                  }
                </Select>
              </Form.Item>
          }
        </Form>
      </div>
    </Modal>
  )
}
export default CompanySelector
