import { executeAction } from '../app/app.actions'
import { actionTypes } from './statistics.types'
import StatisticsService from './statistics.service'
import { groupBy } from '../../util/array.utils'

const processStatistics = (data) => {
  const result = { requests: [], results: [] }

  result.requests = data.requests.map(({ status, total, progress, finished }) => {
    const color = finished ? 'green' : progress ? 'orange' : status === 'INGRESO' ? 'grey' : '#038FDD'
    const name = finished ? 'Terminadas' : progress ? 'En progreso' : status === 'INGRESO' ? 'Creadas' : 'Enviadas'
    return { name, value: total, color }
  }).filter(item => item.value > 0)

  result.results = Object.values(groupBy(data.results, 'branchId')).map(branch => {
    return branch.reduce((acc, current) => {
      switch (current.result) {
        case 1:
          acc.apto = current.total
          return acc
        case 2:
          acc.aptoCR = current.total
          return acc
        case 3:
          acc.noApto = current.total
          return acc
        default:
          return acc
      }
    }, { name: branch[0].branchName, apto: 0, aptoCR: 0, noApto: 0 })
  })
  return result
}

export const getCompanyStatistics = () => dispatch => {
  const process = async () => {
    const { data } = await StatisticsService.byCompany()
    return processStatistics(data)
  }
  dispatch(executeAction(actionTypes.COMPANY_STATISTICS, process))
}

export const getBranchStatistics = ({ branchId }) => dispatch => {
  const process = async () => {
    const { data } = await StatisticsService.byBranch({ branchId })
    return processStatistics(data)
  }
  dispatch(executeAction(actionTypes.BRANCH_STATISTICS, process))
}

export const getUserStatistics = () => dispatch => {
  const process = async () => {
    const { data } = await StatisticsService.byUser()
    return processStatistics(data)
  }
  dispatch(executeAction(actionTypes.USER_STATISTICS, process))
}

export const clearStatistics = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_STATISTICS))
}
