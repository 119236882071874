export const actionTypes = {
  GET_SURVEY: 'surveys',
  GET_QUESTION: 'surveys/question/get',
  ADD_QUESTION: 'surveys/question/add',
  UPDATE_QUESTION: 'surveys/question/update',
  DELETE_QUESTION: 'surveys/question/delete',
  DELETE_QUESTION_OPTION: 'surveys/question/delete/option',
  ADD_QUESTION_OPTION: 'surveys/question/add',
  CLEAR_SURVEYS: 'surveys/clear',
  UPDATE_QUESTION_POSITIONS: 'surveys/question/positions'
}
