import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Modal, Row, Tabs } from 'antd'
import { selectSingleAssignments } from '../../store/assignments/assignments.selector'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import AssignmentForm from '../../components/Surveys/AssignmentForm'
import { useParams } from 'react-router-dom'
import { editAssignment, getSingleAssignment } from '../../store/assignments/assignments.actions'
import moment from 'moment'
import { GENERIC_MESSAGE_ERROR } from '../../util/messages.utils'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { actionTypes } from '../../store/assignments/assignments.types'

const TabPane = Tabs.TabPane
const { success, error } = Modal
const dateFormat = 'DD/MM/YYYY'

const SingleAssignment = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { assignmentId } = useParams()
  const assignment = useSelector(selectSingleAssignments)
  const isProcessed = assignment?.sentDate != null

  const [, editFinished] = useIsLoading([actionTypes.EDIT_ASSIGNMENT])
  const [editError, editHasError] = useHasErrors([actionTypes.EDIT_ASSIGNMENT])

  const init = () => {
    dispatch(getSingleAssignment(assignmentId))
  }

  const onFinish = ({ name, contactPhone, position, comments, start, end }) => {
    dispatch(editAssignment({
      id: assignmentId,
      name,
      contactPhone,
      position,
      comments,
      start: moment(start).format(dateFormat),
      end: moment(end).format(dateFormat)
    }))
  }

  useEffect(init, [])

  useEffect(() => {
    if (assignment != null) {
      form.setFieldsValue({ nombre: assignment.name, contactPhone: assignment.contactPhone, position: assignment.position, comments: assignment.comment })
    } else {
      form.resetFields()
    }
  }, [form, assignment])

  useEffect(() => {
    if (editFinished) {
      if (editHasError) {
        error({
          title: '¡Uh-oh!',
          content: editError.message || GENERIC_MESSAGE_ERROR
        })
      } else {
        success({
          title: 'Éxito',
          content: 'Encuesta editada'
        })
      }
    }
  }, [editError.message, editFinished, editHasError])

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <CustomScrollbars className='candidate-tabs-scroll'>
              <div style={{ padding: '28px' }}>
                <Tabs type='card'>
                  <TabPane tab='Encuesta' key='1'>
                    <AssignmentForm assignment={assignment} onFinish={onFinish} form={form} />
                    {
                      !isProcessed &&
                        <Row justify='end' className='gx-pr-3'>
                          <Button type='primary' onClick={() => form.submit()}>
                            Guardar Cambios
                          </Button>
                        </Row>
                    }
                  </TabPane>
                </Tabs>
              </div>
            </CustomScrollbars>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleAssignment
