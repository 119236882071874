import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, InputNumber, Row, Select, Tag, Tooltip } from 'antd'
import { inputRequired } from '../../util/forms.utils'
import { addQuestionOption, deleteQuestionOption } from '../../store/surveys/surveys.actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompanySurveyQuestion } from '../../store/surveys/surveys.selector'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/surveys/surveys.types'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const { Option } = Select
const InputGroup = Input.Group

const QuestionForm = ({ question, form, onFinish, setNewOptions }) => {
  const dispatch = useDispatch()
  const [optionsForm] = Form.useForm()
  const [type, setType] = useState(null)
  const [options, setOptions] = useState([])
  const [newOption] = useState('')
  const [, addQuestionIsFinished] = useIsLoading([actionTypes.ADD_QUESTION])
  const questionSelector = useSelector(selectCompanySurveyQuestion)

  const onTypeChange = (value) => {
    setType(value)
  }

  const closeTag = (option, e) => {
    e.preventDefault()
    if (question) {
      dispatch(deleteQuestionOption({ questionId: question.id, optionId: option.id }))
    } else {
      const opts = options
      const index = opts.map((o) => o.text).indexOf(option.text)
      if (index >= 0) {
        opts.splice(index, 1)
      }
      setOptions([...opts])
      setNewOptions([...opts])
    }
  }

  const addOption = () => {
    const val = optionsForm.getFieldValue('newOptionField')
    if (question && val.length > 0) {
      dispatch(addQuestionOption({ questionId: question.id, text: val }))
      optionsForm.resetFields()
    } else {
      const opts = options
      const index = options.map(item => item.text).indexOf(val)
      if (index >= 0) { return }
      opts.push({ text: val })
      setOptions([...options])
      setNewOptions([...opts])
      optionsForm.resetFields()
    }
  }

  useEffect(() => {
    if (addQuestionIsFinished) {
      setOptions([])
      setType('')
    }
  }, [addQuestionIsFinished])

  const init = () => {
    if (question) { setOptions(question.options) }
    return () => {
      setOptions([])
    }
  }

  useEffect(init, [])
  useEffect(init, [questionSelector])

  return (
    <div>
      <Form
        layout='horizontal'
        name='register'
        labelAlign='left'
        scrollToFirstError
        onFinish={onFinish}
        form={form}
        {...formItemLayout}
      >
        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='question'
                label='Pregunta'
                rules={[inputRequired]}
                labelCol={{ sm: { span: 4 } }}
                wrapperCol={{ sm: { span: 20 } }}
                initialValue={question?.name}
              >
                <Input placeholder='Pregunta' />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='type'
                label='Tipo'
                rules={[inputRequired]}
                labelCol={{ sm: { span: 4 } }}
                wrapperCol={{ sm: { span: 20 } }}
                initialValue={question?.type}
              >
                <Select style={{ width: 300 }} onChange={onTypeChange} disabled={question?.id}>
                  <Option value='radio'>Opcion Unica</Option>
                  <Option value='check-box'>Opcion Multiple</Option>
                  <Option value='text'>Pregunta Abierta</Option>
                  <Option value='range'>Rango</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>
        <hr />
        {question?.type === null &&
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <div style={{ height: '2em' }} />
              <div> Selecciona un tipo de pregunta {} </div>
            </Col>
          </Row>}
        {(type === 'range' || (question && question.type === 'range')) &&
          <InputGroup>
            <Row>
              <Col sm={12}>
                <Form.Item
                  name='range'
                  label='Maximo'
                  rules={[inputRequired]}
                  labelCol={{ sm: { span: 4 } }}
                  wrapperCol={{ sm: { span: 20 } }}
                  initialValue={question?.limit}
                >
                  <InputNumber min={1} placeholder='Valor' />
                </Form.Item>
              </Col>
              <Col sm={12} />
            </Row>
          </InputGroup>}
      </Form>
      {
        (((type !== null) && (type === 'check-box' || type === 'radio')) || (question && (question.type === 'check-box' || question.type === 'radio'))) &&
          <Row>
            <Col span={12}>
              <Form
                layout='vertical'
                name='optionsForm'
                labelAlign='left'
                scrollToFirstError
                onFinish={addOption}
                form={optionsForm}
              >
                <Form.Item
                  label='Nueva Opcion'
                  name='newOptionField'
                  value={newOption}
                >
                  <Input placeholder='Opcion' />
                </Form.Item>
                <Form.Item style={{ paddingTop: '15px' }}>
                  <Button className='ant-btn green-button flex-color-button' type='primary' onClick={addOption}>Agregar</Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12} style={{ marginTop: '0px' }}>
              <label htmlFor='optionsForm_newOptionField' className='Opciones guardadas' title=''>Opciones guardadas</label>
              <div style={{ height: '10px' }} />
              <div>
                {options.map((option, index) =>
                  <div key={index} className='automatic-answers-wrapper'>
                    <div style={{ marginRight: '10px' }}>
                      #{index + 1}.
                    </div>
                    <Tag style={{ width: '100%', textAlign: 'left', paddingLeft: '20px', borderWidth: '1px', borderColor: '#feb736' }} className='clickable-status-tag automatic-answer'>
                      {option.text}
                    </Tag>
                    <Tooltip title='Eliminar'>
                      <i onClick={(e) => closeTag(option, e)} style={{ paddingBottom: '10px' }} className='icon icon-btn icon-trash ' />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Col>
          </Row>
      }
    </div>
  )
}
export default QuestionForm
