import React, { useEffect } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { inputRequired, typeEmail } from '../../util/forms.utils'

const InputGroup = Input.Group

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const SingleCompanyForm = ({ company, form, onFinish, isProcessed }) => {
  useEffect(() => {
    if (Object.keys(company).length) {
      form.setFieldsValue(company)
    } else {
      form.resetFields()
    }
  }, [company, form])

  return (
    <Form
      layout='horizontal'
      form={form}
      name='register'
      labelAlign='left'
      onFinish={onFinish}
      scrollToFirstError
      {...formItemLayout}
    >
      <div className='form--item__company'>
        <Form.Item
          name='name'
          label='Nombre'
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input className='disabled-input company-input' readOnly={isProcessed} placeholder='Nombre' disabled />
        </Form.Item>

        <Form.Item
          name='address'
          label='Direccion'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input className='disabled-input company-input' readOnly={isProcessed} placeholder='Direccion' />
        </Form.Item>

        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='phone'
                label='Telefono'
                rules={[inputRequired]}
              >
                <Input className='company-input' readOnly={isProcessed} placeholder='Telefono de la empresa' />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='email'
                label='Correo'
                rules={[typeEmail, inputRequired]}
              >
                <Input className='company-input' type='email' readOnly={isProcessed} placeholder='email@ejemplo.com' />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <Form.Item
          name='contactName'
          label='Contacto'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input className='disabled-input company-input' readOnly={isProcessed} placeholder='Contacto' />
        </Form.Item>
      </div>
    </Form>
  )
}

export default SingleCompanyForm
