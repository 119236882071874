import { $http, downloadFile } from '../../util/http.utils'

class InvoiceService {
  searchInvoices = ({ companyId, active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty, from, to }) =>
    $http.get(`/company/${companyId}/invoice`, { params: { active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty, from, to } })

  downloadInvoice = ({ companyId, invoiceId }) => downloadFile(`/company/${companyId}/invoice/${invoiceId}/download`)
}

export default new InvoiceService()
