import React from 'react'
import { Col, Form, Input, Row } from 'antd'
import { inputRequired } from '../../util/forms.utils'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const BranchForm = ({ branch, onFinish, isProcessed, form }) => {
  const InputGroup = Input.Group

  return (
    <Form
      layout='horizontal'
      form={form}
      name='register'
      labelAlign='left'
      onFinish={onFinish}
      {...formItemLayout}
      scrollToFirstError
    >
      <Form.Item
        name='name'
        label='Nombre'
        rules={[inputRequired]}
        labelCol={{ sm: { span: 4 } }}
        wrapperCol={{ sm: { span: 20 } }}
        initialValue={branch?.name}
      >
        <Input readOnly={isProcessed} placeholder='Nombre' />
      </Form.Item>

      <Form.Item
        name='address'
        label='Direccion'
        rules={[inputRequired]}
        labelCol={{ sm: { span: 4 } }}
        wrapperCol={{ sm: { span: 20 } }}
        initialValue={branch?.address}
      >
        <Input readOnly={isProcessed} placeholder='Direccion' />
      </Form.Item>

      <Form.Item
        name='contactName'
        label='Contacto'
        rules={[inputRequired]}
        labelCol={{ sm: { span: 4 } }}
        wrapperCol={{ sm: { span: 20 } }}
        initialValue={branch?.contactName}
      >
        <Input readOnly={isProcessed} placeholder='Contacto' />
      </Form.Item>

      <InputGroup>
        <Row>
          <Col sm={12}>
            <Form.Item
              name='phone'
              label='Telefono'
              rules={[inputRequired]}
              initialValue={branch?.phone}
            >
              <Input readOnly={isProcessed} placeholder='5555-5555' />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              name='email'
              label='Email'
              rules={[inputRequired]}
              initialValue={branch?.email}
            >
              <Input readOnly={isProcessed} placeholder='email@ejemplo.com' />
            </Form.Item>
          </Col>
        </Row>
      </InputGroup>

    </Form>
  )
}

export default BranchForm
