import { $http, downloadFile } from '../../util/http.utils'

class CandidatesService {
  search =
    ({ page, pageSize, sortOrder, sortProperty, last, from, to, search, active, deleted, status, pending = true, investigating = true, result = 0, byUser }) =>
      $http.get('/candidates', {
        params: {
          page,
          pageSize,
          sortOrder,
          sortProperty,
          last,
          from,
          to,
          search,
          searchProperty: 'name',
          active,
          deleted,
          status,
          pending,
          investigating,
          result,
          byUser
        }
      })

  addCandidate =
    ({ name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family }) =>
      $http.post('/candidates', { name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family })

  editCandidate =
    ({ id, name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family }) =>
      $http.put(`/candidates/${id}`, { name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family })

  deleteCandidate =
    ({ id }) =>
      $http.delete(`/candidates/${id}`)

  deleteCandidates =
    (ids) =>
      $http.post('/candidates/delete', { ids })

  processCandidate =
    ({ id }) =>
      $http.post(`/candidates/${id}/process`)

  processCandidates =
    (ids) =>
      $http.post('/candidates/process', { ids })

  singleCandidate =
    ({ id }) =>
      $http.get(`/candidates/${id}`)

  candidateJobs =
    ({ id }) =>
      $http.get(`/candidates/${id}/job`)

  reassignCandidate =
    ({ candidateId, userId }) =>
      $http.post(`/candidates/${candidateId}/reasign`, { userId })

  addJob =
    ({ candidateId, company, lada, phone, position, boss, start, end, reason }) =>
      $http.post(`/candidates/${candidateId}/job`, { company, lada, phone, position, boss, start, end, reason })

  deleteJob =
    ({ candidateId, jobId }) =>
      $http.delete(`/candidates/${candidateId}/job/${jobId}`)

  editJob =
    ({ candidateId, jobId, company, lada, phone, position, boss, start, end, reason }) =>
      $http.put(`/candidates/${candidateId}/job/${jobId}`, {
        candidateId,
        jobId,
        company,
        lada,
        phone,
        position,
        boss,
        start,
        end,
        reason
      })

  getReport = ({ candidateId }) =>
    downloadFile(`/candidates/${candidateId}/report`)
}

export default new CandidatesService()
