import React from 'react'
import { Avatar, Checkbox, Modal, Tooltip } from 'antd'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { deleteAssignment, processAssignment } from '../../store/assignments/assignments.actions'

const dateFormat = 'DD/MM/YYYY'
const { confirm } = Modal

const AssignmentCell = ({ assignment, isSelected, onSelect }) => {
  const dispatch = useDispatch()

  const confirmDelete = () => {
    confirm({
      okType: 'danger',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <ExclamationCircleOutlined />,
      content: '¿Desea eliminar esta encuesta? Esta operación no se puede revertir.',
      onOk: () => dispatch(deleteAssignment(assignment.id))
    })
  }

  const confirmToProcess = () => {
    confirm({
      onType: 'danger',
      onText: 'Procesar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <QuestionCircleOutlined />,
      content: `¿Está seguro que desea enviar ${assignment.name.toUpperCase()} a procesar? Esta operación no se puede revertir`,
      onOk: () => dispatch(processAssignment(assignment.id))
    })
  }

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <Checkbox
          className='gx-icon-btn'
          onClick={() => onSelect(assignment.id)}
          checked={isSelected(assignment.id)}
        />
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar
            // style={{ backgroundColor: randomHexColorByString(assignment.name), verticalAlign: 'middle' }}
            style={{ backgroundColor: 'green', verticalAlign: 'middle' }}
            size='large'
          >
            {assignment.name[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row>
            <Col span={2}>
              <Tooltip title='id'>
                <span className='gx-text-truncate'>#{assignment.id}</span>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title='Creado en'>
                <span className='gx-text-truncate'>{moment(assignment.createdAt).format(dateFormat)}</span>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Nombre'>
                <span className='gx-text-uppercase gx-text-truncate'>{assignment.name} </span>
                <div className='gx-text-muted'>
                  <span className='gx-email-block gx-mr-2'>
                    {assignment.position}
                  </span>
                </div>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip title='Periodo'>
                <span className='gx-text-truncate'>{moment(assignment.startDate).format(dateFormat)} - {moment(assignment.endDate).format(dateFormat)}</span>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title='Contacto'>
                <span className='gx-text-truncate'>{assignment.contactPhone}</span>
              </Tooltip>
            </Col>
          </Row>
        </div>
        <div className='gx-module-contact-right'>
          <Row>
            <Col span={8}>
              <Tooltip title='Eliminar' onClick={confirmDelete}>
                <i className='gx-icon-btn icon icon-trash' />
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Editar'>
                <Link to={`/surveys/assignments/${assignment.id}`}><i className='gx-icon-btn icon icon-edit' /></Link>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Procesar' onClick={confirmToProcess}>
                <i className='gx-icon-btn icon icon-menu-right' />
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default AssignmentCell
