import React, { useEffect, useState } from 'react'
import { Button, Form, Input } from 'antd'
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined'
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined'
import { inputRequired, typeEmail } from '../../util/forms.utils'
import { useDispatch, useSelector } from 'react-redux'
import { hideLogout, login } from '../../store/auth/auth.actions'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/auth/auth.types'
import { useHasErrors } from '../../store/app/error/error.hooks'
import SweetAlert from 'react-bootstrap-sweetalert'
import { history } from '../../store'
import { selectIsLogout } from '../../store/auth/auth.selectors'
import {UNEXPECTED_OOPS} from "../../util/messages.utils";

const FormItem = Form.Item

const Login = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [loading, finished] = useIsLoading([actionTypes.LOGIN])
  const [APIError, hasError] = useHasErrors([actionTypes.LOGIN])
  const [showAlert, setShowAlert] = useState(false)
  const isLogout = useSelector(selectIsLogout)
  const toggleAlert = () => {
    setShowAlert(!showAlert)
  }

  const onSubmit = ({ email, password }) => {
    if (email !== '' && password !== '') {
      dispatch(login(email, password))
    }
  }

  useEffect(() => {
    if (finished) {
      if (hasError) {
        setShowAlert(true)
      } else {
        history.replace('/')
      }
    }
  }, [APIError, finished, hasError])

  return (
    <div className='gx-login-container'>
      <div className='gx-login-content'>
        <div className='gx-login-header gx-text-center'>
          <img src='/LogoRHG.svg' alt='logo' />
        </div>
        <Form name='basic' className='gx-signin-form gx-form-row0' form={form} onFinish={onSubmit}>
          <FormItem rules={[inputRequired, typeEmail]} name='email'>

            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Email'
            />
          </FormItem>
          <FormItem rules={[inputRequired]} name='password'>

            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type='password'
              placeholder='Password'
            />
          </FormItem>
          <FormItem className='gx-text-center'>
            <Button type='primary' htmlType='submit' loading={loading}>
              Ingresar
            </Button>
          </FormItem>
        </Form>
      </div>
      <SweetAlert
        error
        show={showAlert}
        title={APIError.message || UNEXPECTED_OOPS}
        onConfirm={toggleAlert}
      />
      <SweetAlert
        error
        show={isLogout}
        title='La sesión ha expirado'
        onConfirm={() => dispatch(hideLogout())}
      />
    </div>
  )
}

export default Login
