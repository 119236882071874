import React from 'react'
import { Avatar, Checkbox, Tooltip, Modal } from 'antd'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { deleteCandidate, processCandidate } from '../../store/candidates/candidates.actions'

const { confirm } = Modal

const CandidateCell = ({ candidate, isSelected, onSelect }) => {
  const dispatch = useDispatch()

  const confirmDelete = () => {
    confirm({
      okType: 'danger',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <ExclamationCircleOutlined />,
      content: `Si elimina a ${candidate.name.toUpperCase()} se perderán todos los datos registrados en este candidato, esta operación no se puede revertir.`,
      onOk: () => dispatch(deleteCandidate(candidate.id))
    })
  }

  const confirmToProcess = () => {
    confirm({
      onType: 'danger',
      onText: 'Procesar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <QuestionCircleOutlined />,
      content: `¿Está seguro que desea enviar ${candidate.name.toUpperCase()} a evaluación? Esta operación no se puede revertir`,
      onOk: () => dispatch(processCandidate(candidate.id))
    })
  }

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <Checkbox
          className='gx-icon-btn'
          onClick={() => onSelect(candidate.id)}
          checked={isSelected(candidate.id)}
        />
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar
            style={{ backgroundColor: 'green', verticalAlign: 'middle' }}
            size='large'
          >
            {candidate.name[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row>
            <Col span={3}>
              <Tooltip title='Folio'>
                <span className='gx-text-truncate'>#{candidate.id} </span>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title='Fecha de creación'>
                <span className='gx-text-truncate'>{moment(candidate.createdAt).format('DD/MM/YYYY')}</span>
              </Tooltip>
            </Col>
            <Col span={11}>
              <Tooltip title='Nombre del candidato'>
                <span className='gx-text-uppercase'>{candidate.name}</span>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip title='Empleos registrados'>
                <span className='gx-text-muted'>{candidate.jobsCount} Empleos</span>
              </Tooltip>
            </Col>
          </Row>
        </div>
        <div className='gx-module-contact-right'>
          <Row>
            <Col span={8}>
              <Tooltip title='Eliminar' onClick={confirmDelete}>
                <i className='gx-icon-btn icon icon-trash' />
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Editar'>
                <Link to={`/candidate/${candidate.id}`}><i className='gx-icon-btn icon icon-edit' /></Link>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Procesar' onClick={confirmToProcess}>
                <i className='gx-icon-btn icon icon-menu-right' />
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default CandidateCell
