import { $http, downloadFile } from '../../util/http.utils'

class AssignmentService {
  search =
    ({ page, pageSize, sortOrder, sortProperty, last, from, to, search, active, deleted, status, pending = true, investigating = true, result = 0, byUser }) =>
      $http.get('/assignments', {
        params: {
          page,
          pageSize,
          sortOrder,
          sortProperty,
          last,
          from,
          to,
          search,
          searchProperty: 'name',
          active,
          deleted,
          status,
          pending,
          investigating,
          result,
          byUser
        }
      })

  addAssignment =
    ({ name, contactPhone, position, comments, start, end }) =>
      $http.post('/assignments', { name, contactPhone, position, comments, start, end })

  editAssignment =
    ({ id, name, contactPhone, position, comments, start, end }) =>
      $http.put(`/assignments/${id}`, { name, contactPhone, position, comments, start, end })

  deleteAssignment =
    ({ id }) =>
      $http.delete(`/assignments/${id}`)

  deleteAssignments =
    (ids) =>
      $http.post('/assignments/delete', { ids })

  processAssignment =
    ({ id }) =>
      $http.post(`/assignments/${id}/process`)

  processAssignments =
    (ids) =>
      $http.post('/assignments/process', { ids })

  singleAssignment =
    ({ id }) =>
      $http.get(`/assignments/${id}`)

  reassignAssignment =
    ({ assignmentId, userId }) =>
      $http.post(`/assignments/${assignmentId}/reasign`, { userId })

  assignmentAnswers =
    ({ assignmentId }) =>
      $http.get(`/assignments/${assignmentId}/answers`)

  getReport = ({ assignmentId }) =>
    downloadFile(`/admin/assignments/${assignmentId}/report`)
}

export default new AssignmentService()
