import { extract, successState } from '../app/app.actions'
import { actionTypes } from './invoice.types'

const initialState = {
  invoices: { page: 1, items: [], pageSize: 10, total: 0, count: 0 }
}

const InvoiceReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.SEARCH_INVOICES):
      return {
        ...state,
        invoices: extract(data, initialState.invoices)
      }
    case successState(actionTypes.CLEAR_INVOICE):
      return initialState
    default:
      return state || initialState
  }
}

export default InvoiceReducer
