import AuthService from './auth.service.js'
import { actionTypes } from './auth.types'
import { executeAction } from '../app/app.actions'

export const login = (email, password) => async dispatch => {
  const process = () => AuthService.login(email, password)
  dispatch(executeAction(actionTypes.LOGIN, process))
}

export const setCurrentCompany = (company) => async dispatch => {
  const process = () => company
  dispatch(executeAction(actionTypes.SET_COMPANY, process))
}

export const setCurrentBranch = (branch) => async dispatch => {
  const process = () => branch
  dispatch(executeAction(actionTypes.SET_BRANCH, process))
}

export const updateCurrentUser = (user) => dispatch => {
  const process = () => AuthService.updateUser(user)
  dispatch(executeAction(actionTypes.UPDATE_USER, process))
}

export const getUserRoles = () => dispatch => {
  const process = () => AuthService.getRoles()
  dispatch(executeAction(actionTypes.GET_ROLES, process))
}

export const hideLogout = () => dispatch => {
  const process = () => false
  dispatch(executeAction(actionTypes.HIDE_LOGOUT, process))
}
