import { $http } from '../../util/http.utils'

class CompanyService {
  getCompany = () =>
    $http.get('/company/current')

  update =
    ({ address, contactName, email, name, phone }) =>
      $http.put('/company', { address, contactName, email, name, phone })
}

export default new CompanyService()
