import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInvoices } from '../../store/invoice/invoice.selector'
import { downLoadInvoice, searchInvoices } from '../../store/invoice/invoice.actions'
import { Form, Pagination, Row } from 'antd'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import InvoiceCell from '../../components/Invoice/InvoiceCell'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/invoice/invoice.types'
import { selectCurrentCompany } from '../../store/auth/auth.selectors'
import { updateTopBarTitle } from '../../store/settings/settings.actions'

const sortProperties = [
  { label: 'Fecha', property: 'fromDate' }
]

const Invoices = () => {
  const dispatch = useDispatch()
  const currentCompany = useSelector(selectCurrentCompany)
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [searchForm] = Form.useForm()
  const invoices = useSelector(selectInvoices)
  const [loading] = useIsLoading([actionTypes.DOWNLOAD_INVOICE])

  const refresh = useCallback(({ active, deleted, last, search, searchProperty, sortOrder, sortProperty, from, to } = {}) => {
    dispatch(searchInvoices({
      companyId: currentCompany.id,
      active,
      deleted,
      last,
      search,
      searchProperty,
      sortOrder,
      sortProperty,
      from: from?.unix(),
      to: to?.unix(),
      ...pagination
    }))
  }, [currentCompany.id, dispatch, pagination])

  const handleDownload = (invoiceId) => {
    dispatch(downLoadInvoice({ companyId: currentCompany?.id, invoiceId }))
  }

  const init = () => {
    dispatch(updateTopBarTitle('Facturación'))
  }

  useEffect(init, [])

  const onPageChange = (page, pageSize) => setPagination({ page, pageSize })

  return (
    <div className='gx-main-content'>
      <div className='gx-app-module'>
        <div className='gx-module-box-content'>
          <LoadingIndicator loading={loading} />
          <br />
          <div className='gx-module-box-topbar flex-header'>
            <CustomSearch
              search={false}
              form={searchForm}
              onChange={refresh}
              sortProperties={sortProperties}
            />
          </div>
          <CustomScrollbars className='candidate-scroll'>
            {
              invoices.items.length === 0
                ? (
                  <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                    No se encontraron facturas con los filtros específicados
                  </div>
                )
                : (invoices.items.map((invoice, index) => {
                  index++
                  return (<InvoiceCell index={index} invoice={invoice} key={`invoice_${invoice?.id}`} handleDownload={handleDownload} />)
                })
                )
            }
          </CustomScrollbars>
          <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
            <Pagination
              showSizeChanger
              defaultCurrent={1}
              onChange={onPageChange}
              total={invoices?.total || 0}
              current={invoices?.page || 0}
              pageSize={invoices?.pageSize || 0}
              hideOnSinglePage={invoices.total === 0}
              showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
            />
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Invoices
