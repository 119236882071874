import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import { Tabs } from 'antd'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import Statistics from './Statistics'
import {
  selectCurrentBranch,
  selectCurrentCompany,
  selectIsAdmin,
  selectIsSupervisor
} from '../../store/auth/auth.selectors'
import {
  selectBranchStatistics,
  selectCompanyStatistics,
  selectUserStatistics
} from '../../store/statistics/statistics.selector'
import { getBranchStatistics, getCompanyStatistics, getUserStatistics } from '../../store/statistics/statistics.actions'
import { clearActionResult } from '../../store/app/app.actions'
import { actionTypes } from '../../store/statistics/statistics.types'

const Dashboard = () => {
  const dispatch = useDispatch()
  const isAdmin = useSelector(selectIsAdmin)
  const isSupervisor = useSelector(selectIsSupervisor)
  const currentBranch = useSelector(selectCurrentBranch)
  const currentCompany = useSelector(selectCurrentCompany)
  const companyStatistics = useSelector(selectCompanyStatistics)
  const branchStatistics = useSelector(selectBranchStatistics)
  const userStatistics = useSelector(selectUserStatistics)
  const init = () => {
    dispatch(updateTopBarTitle('Inicio'))
    if (currentCompany != null && currentBranch != null) {
      if (isAdmin) {
        dispatch(getCompanyStatistics())
        dispatch(getBranchStatistics({ branchId: currentBranch?.id }))
        dispatch(getUserStatistics())
      } else if (isSupervisor) {
        dispatch(clearActionResult(actionTypes.COMPANY_STATISTICS))
        dispatch(getBranchStatistics({ branchId: currentBranch?.id }))
        dispatch(getUserStatistics())
      } else {
        dispatch(clearActionResult(actionTypes.COMPANY_STATISTICS))
        dispatch(clearActionResult(actionTypes.BRANCH_STATISTICS))
        dispatch(getUserStatistics())
      }
    }
  }
  useEffect(init, [currentBranch, currentCompany])
  const defaultKey = isAdmin ? '1' : isSupervisor ? '2' : '3'

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <CustomScrollbars className='dashboard-tabs-scroll'>
              <Tabs type='card' style={{ backgroundColor: 'white', paddingTop: 20 }} defaultActiveKey={defaultKey}>
                {
                  isAdmin &&
                    <Tabs.TabPane tab='Estadisticas de la empresa' key='1'>
                      <Statistics results={companyStatistics.results} requests={companyStatistics.requests} company />
                    </Tabs.TabPane>
                }
                {
                  (isAdmin || isSupervisor) && currentBranch != null &&
                    <Tabs.TabPane tab={`Estadisticas de ${currentBranch.name}`} key='2'>
                      <Statistics results={branchStatistics.results} requests={branchStatistics.requests} />
                    </Tabs.TabPane>
                }
                {
                  currentBranch != null &&
                    <Tabs.TabPane tab='Mis estadisticas' key='3'>
                      <Statistics results={userStatistics.results} requests={userStatistics.requests} />
                    </Tabs.TabPane>
                }
              </Tabs>
            </CustomScrollbars>
          </div>
        </div>
      </div>
    </div>

  )
}
export default Dashboard
