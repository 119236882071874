import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Avatar, Col, Modal, Row, Tag, Timeline, Tooltip } from 'antd'
import moment from 'moment'
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined'
import { Link } from 'react-router-dom'
import { downloadAssignmentReport } from '../../store/assignments/assignments.actions'

const CompletedAssignmentCell = ({ assignment }) => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()

  const getTagByStatus = (assignment) => {
    if (assignment.noAnswer) { return <Tag color='#FF5A87' style={{ fontSize: '15px', minWidth: '150px' }} className='status-tag'>SIN RESPUESTA</Tag> } else { return <Tag color='#87D068' style={{ fontSize: '15px', minWidth: '150px' }} className='status-tag'>COMPLETADO</Tag> }
  }

  const downloadReport = () => {
    dispatch(downloadAssignmentReport({ assignmentId: assignment.id }))
  }

  const toggleModal = () => { setShowModal(!showModal) }

  return (
    <div className='cell-border'>
      <div className='gx-contact-item' style={{ cursor: 'pointer' }}>
        <div className='gx-module-list-icon'>
          <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
            <Avatar style={{ backgroundColor: '#AA3E98', verticalAlign: 'middle' }} size='large'>
              {assignment.name[0].toUpperCase()}
            </Avatar>
          </div>
        </div>
        <div className='gx-module-list-info gx-contact-list-info'>
          <div className='gx-module-contact-content'>
            <Row align='middle'>
              <Col span={3}>
                <Tooltip title='Folio'>
                  <span className='gx-text-truncate'>#{assignment.id} </span>
                </Tooltip>
              </Col>
              <Col span={3}>
                <Tooltip title='Fecha de entrega'>
                  <span className='gx-text-truncate'>{moment(assignment.sentDate).format('DD/MM/yyyy')} </span>
                </Tooltip>
              </Col>
              <Col span={10}>
                <Tooltip title='Nombre del encuestado'>
                  <span className='gx-text-uppercase'>{assignment.name}</span>
                </Tooltip>
              </Col>
              <Col span={4}>
                <Tooltip title='Usuario'>
                  <span className='gx-text-muted' style={{ fontSize: 12 }}>{assignment.createdByName}</span>
                </Tooltip>
              </Col>
              <Col span={4}>
                {getTagByStatus(assignment)}
              </Col>
            </Row>
          </div>
          <div className='gx-module-contact-right'>
            <Row>
              <Col span={8} />
              <Col span={4}>
                <br />
                <div style={{ paddingBottom: '10px' }}>
                  <Tooltip title='Descargar' onClick={downloadReport}>
                    <DownloadOutlined />
                  </Tooltip>
                </div>
              </Col>
              <Col span={4}>
                <Tooltip title='Historial' className='flex-color-button'>
                  <i onClick={toggleModal} className='gx-icon-btn icon icon-timeline-new flex-icon-button-3' />
                </Tooltip>
              </Col>
              <Col span={4}>
                <Tooltip title='Ver detalles'>
                  <Link to={`/surveys/completed/${assignment.id}`}>
                    <i className='gx-icon-btn icon icon-eye' />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal
        title='Historial'
        visible={showModal}
        onOk={toggleModal}
        onCancel={toggleModal}
      >
        <div>
          <Timeline>
            <Timeline.Item color='green'>
              Encuesta creada: {moment(assignment.createdAt).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color='green'>
              Encuesta solicitada: {moment(assignment.sentDate).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color='green'>
              Inicio de proceso: {moment(assignment.investigationDate).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color='green'>
              Resultados: {moment(assignment.finishDate).format('DD/MM/yyyy')}
            </Timeline.Item>
          </Timeline>
        </div>
      </Modal>
    </div>
  )
}

export default CompletedAssignmentCell
