import CandidateService from './candidates.service'
import { executeAction } from '../app/app.actions'
import { actionTypes } from './candidates.types'
import { history } from '../index'

export const searchCandidates = ({ page = 1, pageSize = 10, sortOrder, sortProperty, last = 10, from, to, search, active, deleted, status, pending = true, investigating = true, result = 0, byUser }) => dispatch => {
  const process = () => CandidateService.search({ page, pageSize, sortOrder, sortProperty, last, from, to, search, active, deleted, status, pending, investigating, result, byUser })
  dispatch(executeAction(actionTypes.SEARCH_CANDIDATES, process))
}

export const addCandidate = ({ name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family }) => dispatch => {
  const process = async () => {
    const candidate = await CandidateService.addCandidate({ name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family })
    history.push(`/candidate/${candidate.data.id}`)
    return candidate
  }
  dispatch(executeAction(actionTypes.ADD_CANDIDATE, process))
}

export const editCandidate = ({ id, name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family }) => dispatch => {
  const process = () => CandidateService.editCandidate({ id, name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family })
  dispatch(executeAction(actionTypes.EDIT_CANDIDATE, process))
}

export const getSingleCandidate = (id) => dispatch => {
  const process = () => CandidateService.singleCandidate({ id })
  dispatch(executeAction(actionTypes.SINGLE_CANDIDATE, process))
}

export const getCandidateJobs = (id) => dispatch => {
  const process = () => CandidateService.candidateJobs({ id })
  dispatch(executeAction(actionTypes.CANDIDATE_JOBS, process))
}

export const deleteCandidate = (id) => (dispatch) => {
  const process = () => CandidateService.deleteCandidate({ id })
  dispatch(executeAction(actionTypes.DELETE_CANDIDATE, process))
}

export const deleteCandidates = ids => dispatch => {
  const process = () => CandidateService.deleteCandidates(ids)
  dispatch(executeAction(actionTypes.DELETE_CANDIDATES, process))
}

export const processCandidate = (id) => (dispatch) => {
  const process = () => CandidateService.processCandidate({ id })
  dispatch(executeAction(actionTypes.PROCESS_CANDIDATE, process))
}

export const processCandidates = (ids) => dispatch => {
  const process = () => CandidateService.processCandidates(ids)
  dispatch(executeAction(actionTypes.PROCESS_CANDIDATES, process))
}

export const reassignCandidate = ({ candidateId, userId }) => dispatch => {
  const process = () => CandidateService.reassignCandidate({ candidateId, userId })
  dispatch(executeAction(actionTypes.REASSIGN_CANDIDATE, process))
}

export const addCandidateJob = ({ candidateId, company, lada, phone, position, boss, start, end, reason }) => dispatch => {
  const process = async () => {
    const result = await CandidateService.addJob({ candidateId, company, lada, phone, position, boss, start, end, reason })
    dispatch(getSingleCandidate(candidateId))
    dispatch(getCandidateJobs(candidateId))
    return result
  }
  dispatch(executeAction(actionTypes.ADD_CANDIDATE_JOBS, process))
}

export const editCandidateJob = ({ candidateId, jobId, company, lada, phone, position, boss, start, end, reason }) => dispatch => {
  const process = async () => {
    const result = await CandidateService.editJob({ candidateId, jobId, company, lada, phone, position, boss, start, end, reason })
    dispatch(getCandidateJobs(candidateId))
    return result
  }
  dispatch(executeAction(actionTypes.EDIT_CANDIDATE_JOB, process))
}

export const deleteCandidateJob = ({ candidateId, jobId }) => dispatch => {
  const process = () => CandidateService.deleteJob({ candidateId, jobId })
  dispatch(executeAction(actionTypes.DELETE_CANDIDATE_JOB, process))
}

export const downloadCandidateReport = ({ candidateId }) => dispatch => {
  const process = () => CandidateService.getReport({ candidateId })
  dispatch(executeAction(actionTypes.DOWNLOAD_REPORT, process))
}

export const clearCandidates = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_CANDIDATES))
}
