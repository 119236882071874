import React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { HomeOutlined, SettingOutlined, TeamOutlined, EnvironmentOutlined, FileDoneOutlined } from '@ant-design/icons'
import SidebarLogo from './SidebarLogo'
import UserProfile from './UserProfile'
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from '../../constants/theme.settings'
import { useSelector } from 'react-redux'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import { selectIsAdmin, selectIsSupervisor } from '../../store/auth/auth.selectors'
import '../../styles/ui/sidebar.less'
import ReconciliationOutlined from '@ant-design/icons/lib/icons/ReconciliationOutlined'

const iconStyles = {
  style: {
    fontSize: '20px'
  }
}

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings)
  const { pathname } = useSelector(({ navigation }) => navigation)
  const isSupervisor = useSelector(selectIsSupervisor)
  const isAdmin = useSelector(selectIsAdmin)

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return 'gx-no-header-notifications'
    }
    return ''
  }

  const selectedKeys = pathname.substr(1) || 'home'
  const defaultOpenKeys = selectedKeys.split('/')[1]
  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className='gx-sidebar-content'>
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
        </div>
        <CustomScrollbars className='max-items'>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode='inline'
          >
            <Menu.Item key='home'>
              <Link to='/'>
                <HomeOutlined {...iconStyles} />
                <span>Inicio</span>
              </Link>
            </Menu.Item>

            <Menu.ItemGroup className='gx-menu-group' title='Candidatos'>
              <Menu.Item key='drafts'>
                <Link to='/drafts'>
                  <i className='icon icon-signup' />
                  <span>Nuevos</span>
                </Link>
              </Menu.Item>

              <Menu.Item key='in-process'>
                <Link to='/in-process'>
                  <i className='icon icon-progress' />
                  <span>En Proceso</span>
                </Link>
              </Menu.Item>

              <Menu.Item key='finished'>
                <Link to='/finished'>
                  <i className='icon icon-check-circle-o' />
                  <span>Terminados</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup className='gx-menu-group' title='Encuestas'>

              <Menu.Item key='surveys/assignments'>
                <Link to='/surveys/assignments'>
                  <i className='icon icon-apps-new' />
                  <span>Nuevas</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='/surveys/process/assignments'>
                <Link to='/surveys/process/assignments'>
                  <i className='icon icon-wysiwyg' />
                  <span>En proceso</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='/surveys/completed/assignments'>
                <Link to='/surveys/completed/assignments'>
                  <i className='icon icon-check-square-o' />
                  <span>Finalizadas</span>
                </Link>
              </Menu.Item>

            </Menu.ItemGroup>

            {
              (isSupervisor || isAdmin) &&
                <>
                  <Menu.ItemGroup className='gx-menu-group' title='Administración'>
                    <Menu.Item key='users'>
                      <Link to='/users'>
                        <TeamOutlined {...iconStyles} />
                        <span>Usuarios</span>
                      </Link>
                    </Menu.Item>

                    <Menu.Item key='invoices'>
                      <Link to='/invoices'>
                        <FileDoneOutlined {...iconStyles} />
                        <span>Facturación</span>
                      </Link>
                    </Menu.Item>

                    {
                      isAdmin &&
                        <Menu.Item key='branches'>
                          <Link to='/branches'>
                            <EnvironmentOutlined {...iconStyles} />
                            <span>Sucursales</span>
                          </Link>
                        </Menu.Item>
                    }

                    {
                      isAdmin &&
                        <Menu.Item key='company'>
                          <Link to='/company'>
                            <SettingOutlined {...iconStyles} />
                            <span>Configuración</span>
                          </Link>
                        </Menu.Item>
                    }

                    <Menu.Item key='questionary'>
                      <Link to='/questionary'>
                        <ReconciliationOutlined {...iconStyles} />
                        <span>Cuestionario</span>
                      </Link>
                    </Menu.Item>

                    {/* <Menu.Item key='zones'> */}
                    {/*  <Link to='/zones'> */}
                    {/*    <GlobalOutlined {...iconStyles} /> */}
                    {/*    <span>Zonas</span> */}
                    {/*  </Link> */}
                    {/* </Menu.Item> */}
                  </Menu.ItemGroup>
                </>
            }
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  )
}

SidebarContent.propTypes = {}
export default SidebarContent
