import React, { useEffect, useState } from 'react'
import Sidebar from '../containers/Sidebar'
import { Layout } from 'antd'
import TopBar from '../containers/Topbar'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentBranch,
  selectCurrentCompany,
  selectCurrentUser,
  selectCurrentUserCompanies,
  selectToken
} from '../store/auth/auth.selectors'
import { history } from '../store'
import CompanySelector from '../pages/Dashboard/CompanySelector'
import { getUserRoles, setCurrentBranch, setCurrentCompany } from '../store/auth/auth.actions'
import { logout } from '../store/auth/logout.actions'

const { Content } = Layout

const Auth = ({ Component }) => {
  const user = useSelector(selectCurrentUser)
  const token = useSelector(selectToken)
  const currentCompany = useSelector(selectCurrentCompany)
  const currentBranch = useSelector(selectCurrentBranch)
  const userCompanies = useSelector(selectCurrentUserCompanies)
  const dispatch = useDispatch()

  const [showCompanySelector, setShowCompanySelector] = useState(false)
  const toggleCompanySelector = () => setShowCompanySelector(!showCompanySelector)

  if (token == null) {
    dispatch(logout())
    history.push('/login')
  }

  const init = () => {
    if (user && token) {
      dispatch(getUserRoles())
    }
    if (currentCompany == null || currentBranch == null) {
      if (userCompanies?.length === 1) {
        const company = userCompanies[0]
        dispatch(setCurrentCompany(company))

        if (company.branches.length === 1) {
          dispatch(setCurrentBranch(company.branches[0]))
        } else {
          setShowCompanySelector(true)
        }
      } else {
        setShowCompanySelector(true)
      }
    }
  }
  useEffect(init, [])

  return (
    <Layout className='gx-app-layout'>
      <Sidebar />
      <Layout>
        <TopBar />
        <Content className='gx-layout-content gx-container-wrap'>
          <div className='gx-main-content-wrapper'>
            <CompanySelector show={showCompanySelector} onConfirm={toggleCompanySelector} cancelable={false} />
            <Component />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
export default Auth
