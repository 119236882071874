import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Pagination, Popover, Row, Select, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { selectBranchUsers } from '../../store/users/users.selectors'
import {
  selectCurrentBranch,
  selectCurrentCompany,
  selectIsAdmin,
  selectIsSupervisor
} from '../../store/auth/auth.selectors'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/assignments/assignments.types'
import { selectAssignments } from '../../store/assignments/assignments.selector'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import { getBranchUsers } from '../../store/users/users.actions'
import { clearActionResult } from '../../store/app/app.actions'
import { searchAssignment } from '../../store/assignments/assignments.actions'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import { UserOutlined } from '@ant-design/icons'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import CompletedAssignmentCell from '../../components/Surveys/CompletedAssignmentCell'

const { Option } = Select

const sortProperties = [
  { label: 'Folio', property: 'id' },
  { label: 'Nombre', property: 'name' },
  { label: 'Fecha de Entrega', property: 'finishDate' }
]

const CompletedAssignments = () => {
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm()
  const [filter] = useState(0)
  const [user, setUser] = useState(0)
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const assignments = useSelector(selectAssignments)
  const data = assignments?.items || []
  const branchUsers = useSelector(selectBranchUsers)
  const isSupervisor = useSelector(selectIsSupervisor)
  const currentCompany = useSelector(selectCurrentCompany)
  const currentBranch = useSelector(selectCurrentBranch)
  const isAdmin = useSelector(selectIsAdmin)
  const access = isSupervisor || isAdmin
  const [loading] = useIsLoading([actionTypes.SEARCH_ASSIGNMENT]) // actionTypes.DOWNLOAD_REPORT

  const refresh = useCallback(({ search, last, from, to, sortOrder, sortProperty } = {}) => {
    dispatch(searchAssignment({
      status: 'TERMINADO',
      search,
      from: from?.unix(),
      to: to?.unix(),
      last,
      result: filter,
      ...pagination,
      sortOrder,
      sortProperty,
      byUser: access ? user : null
    }))
  }, [access, dispatch, filter, pagination, user])

  const init = () => {
    dispatch(updateTopBarTitle('Encuestas Completadas'))
    dispatch(searchAssignment({ status: 'TERMINADO' }))
    if (isSupervisor) {
      dispatch(getBranchUsers())
    }
    return () => dispatch(clearActionResult(actionTypes.SEARCH_ASSIGNMENT))
  }
  useEffect(init, [])

  useEffect(refresh, [currentCompany, currentBranch])

  const onPageChange = (page, pageSize) => setPagination({ page, pageSize })

  const getUserFilters = () => {
    return (
      <Row className='gx-p-2'>
        <Col>
          <Select style={{ width: 200 }} showSearch optionFilterProp='children' value={user} onChange={setUser}>
            <Option value={0}>Todos</Option>
            {
              (branchUsers || []).map((user) =>
                <Option key={`users-${user.id}`} value={user.id}>{user.name}</Option>
              )
            }
          </Select>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={loading} />
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <CustomSearch form={searchForm} onChange={refresh} sortProperties={sortProperties} />
              <div style={{ borderLeft: '1px solid grey', height: 35, marginLeft: 30, marginRight: 5, marginTop: -15 }} />
              {
                access &&
                  <Row justify='end' style={{ marginLeft: 25 }}>
                    <Tooltip title='Filtrar por usuario'>
                      <Popover content={getUserFilters()} title='Filtrar por usuario' trigger='click' placement='bottomRight'>
                        <Button icon={<UserOutlined />} />
                      </Popover>
                    </Tooltip>
                  </Row>
              }
              <span style={{ paddingRight: 30 }} />
            </div>
            <CustomScrollbars className='completed-scroll'>
              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      No se encontraron encuestas con los filtros específicados
                    </div>
                  )
                  : (
                    <div>
                      {data.map((candidate, index) =>
                        <CompletedAssignmentCell assignment={candidate} key={index} />
                        // <div>A</div>
                      )}
                      <div style={{ height: '1em' }} />
                    </div>
                  )
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={assignments.total || 0}
                current={assignments.page || 0}
                pageSize={assignments.pageSize || 0}
                hideOnSinglePage={assignments.total === 0}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompletedAssignments
