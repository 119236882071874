import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import { Button, Checkbox, Form, Modal, Pagination, Row, Tooltip } from 'antd'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import AssignmentForm from '../../components/Surveys/AssignmentForm'
import {
  addAssignment,
  deleteAssignments,
  processAssignments,
  searchAssignment
} from '../../store/assignments/assignments.actions'
import { selectAssignments } from '../../store/assignments/assignments.selector'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/assignments/assignments.types'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import AssignmentCell from '../../components/Surveys/AssignmentCell'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { selectCurrentBranch, selectCurrentCompany, selectIsOperator } from '../../store/auth/auth.selectors'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { UNEXPECTED_OOPS } from '../../util/messages.utils'
import { clearActionResult } from '../../store/app/app.actions'
import moment from 'moment'
import { getCompanySurvey } from '../../store/surveys/surveys.actions'
import { selectCompanySurvey } from '../../store/surveys/surveys.selector'

const sortProperties = [
  { label: 'Folio', property: 'id' },
  { label: 'Nombre', property: 'name' },
  { label: 'Posicion', property: 'position' },
  { label: 'Fecha', property: 'createdAt' }
]

const { confirm, success, error } = Modal
const dateFormat = 'DD/MM/YYYY'

const Assignments = () => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [selected, setSelected] = useState([])
  const [form] = Form.useForm()
  const [searchForm] = Form.useForm()
  const isOperator = useSelector(selectIsOperator)
  const dispatch = useDispatch()
  const assignments = useSelector(selectAssignments)
  const survey = useSelector(selectCompanySurvey)
  const data = assignments?.items || []
  const [asignmentsLoading] = useIsLoading([actionTypes.SEARCH_ASSIGNMENT])
  const [deleteIsLoading, deleteFinished] = useIsLoading([actionTypes.DELETE_ASSIGNMENT])
  const [saveIsLoading, saveFinished] = useIsLoading([actionTypes.ADD_ASSIGNMENT])
  const [saveAssignmentError, saveAssignmentHasError] = useHasErrors([actionTypes.ADD_ASSIGNMENT])
  const [processAssignmentIsLoading, processAssignmentFinished] = useIsLoading([actionTypes.PROCESS_ASSIGNMENT])
  const [processAssignmentsIsLoading, processAssignmentsFinished] = useIsLoading([actionTypes.PROCESS_ASSIGNMENTS])
  const [processAssignmentError, processAssignmentHasError] = useHasErrors([actionTypes.PROCESS_ASSIGNMENT])
  const [processAssignmentsError, processAssignmentsHasError] = useHasErrors([actionTypes.PROCESS_ASSIGNMENTS])
  const [deleteError, deleteHasError] = useHasErrors([actionTypes.DELETE_ASSIGNMENT])
  const [deleteAssignmentsError, deleteAssignmentsHasError] = useHasErrors([actionTypes.DELETE_ASSIGNMENTS])
  const [deleteAssignmentsIsLoading, deleteAssignmentsFinished] = useIsLoading([actionTypes.DELETE_ASSIGNMENTS])

  const refresh = useCallback(({ search, last, from, to, sortOrder, sortProperty } = {}) => {
    setSelected([])
    dispatch(searchAssignment({
      status: 'INGRESO',
      search,
      from: from?.unix(),
      to: to?.unix(),
      last,
      ...pagination,
      sortOrder,
      sortProperty
    }))
  }, [dispatch, pagination])

  const currentCompany = useSelector(selectCurrentCompany)
  const currentBranch = useSelector(selectCurrentBranch)

  const init = () => {
    dispatch(updateTopBarTitle('Encuestas'))
    dispatch(searchAssignment({ status: 'INGRESO' }))
    dispatch(getCompanySurvey())
    return () => dispatch(clearActionResult(actionTypes.SEARCH_ASSIGNMENT))
  }
  useEffect(init, [])

  useEffect(refresh, [currentCompany, currentBranch])

  useEffect(() => {
    if (saveFinished) {
      refresh()
      if (saveAssignmentHasError) {
        error({
          title: '¡Uh-oh!',
          content: saveAssignmentError.message || UNEXPECTED_OOPS
        })
      } else {
        setShowModal(false)
        form.resetFields()
        success({
          title: '¡Exito!',
          content: 'Encuesta creada exitosamente'
        })
        refresh()
      }
    }
  }, [refresh, saveFinished, saveAssignmentError, saveAssignmentHasError, form])

  useEffect(() => {
    if (processAssignmentFinished) {
      if (processAssignmentHasError) {
        error({
          title: '¡Uh-oh!',
          content: processAssignmentError.message || UNEXPECTED_OOPS
        })
      } else {
        setShowModal(false)
        success({
          title: '¡Exito!',
          content: 'Encuesta procesada exitosamente'
        })
        refresh()
      }
    }
  }, [processAssignmentFinished, processAssignmentHasError, processAssignmentError.message, refresh])

  useEffect(() => {
    if (processAssignmentsFinished) {
      if (processAssignmentsHasError) {
        error({
          title: '¡Uh-oh!',
          content: processAssignmentsError.message || UNEXPECTED_OOPS
        })
      } else {
        setShowModal(false)
        success({
          title: '¡Exito!',
          content: 'Encuestas procesadas exitosamente'
        })
        refresh()
      }
    }
  }, [processAssignmentsFinished, processAssignmentsHasError, processAssignmentsError.message, refresh])

  useEffect(() => {
    if (deleteAssignmentsFinished) {
      if (deleteAssignmentsHasError) {
        error({
          title: '¡Uh-oh!',
          content: deleteAssignmentsError.message || UNEXPECTED_OOPS
        })
      } else {
        setShowModal(false)
        success({
          title: '¡Exito!',
          content: 'Encuestas eliminadas exitosamente'
        })
        refresh()
      }
    }
  }, [deleteAssignmentsFinished, deleteAssignmentsHasError, deleteAssignmentsError.message, refresh])

  useEffect(() => {
    if (deleteFinished) {
      if (deleteHasError) {
        error({
          title: '¡Uh-oh!',
          content: deleteError.message
        })
      } else {
        success({
          title: '¡Exito!',
          content: 'Encuesta eliminada exitosamente'
        })
        refresh()
      }
    }
  }, [deleteError.message, deleteFinished, deleteHasError, refresh])

  const onPageChange = (page, pageSize) => {
    setPagination({ page, pageSize })
    const search = searchForm.getFieldValue('search')
    const from = searchForm.getFieldValue('from')
    const to = searchForm.getFieldValue('to')
    const last = searchForm.getFieldValue('last')

    dispatch(searchAssignment({
      status: 'INGRESO',
      search,
      last,
      from: from?.unix(),
      to: to?.unix(),
      page,
      pageSize,
      sortProperty: assignments.sortProperty,
      sortOrder: assignments.sortOrder,
      byUser: null
    }))
  }

  const saveAssignment = ({ name, contactPhone, position, comments, start, end }) => {
    dispatch(addAssignment({
      name,
      contactPhone,
      position,
      comments,
      start: moment(start).format(dateFormat),
      end: moment(end).format(dateFormat)
    }))
  }

  const selectAssignment = (id) => {
    const index = selected.indexOf(id)
    if (index !== -1) {
      selected.splice(index, 1)
      setSelected([...selected])
    } else {
      setSelected([...selected, id])
    }
  }

  const isSelected = id => selected.indexOf(id) !== -1
  const toggleSelectAll = () => {
    if (selected.length < assignments.items.length) {
      setSelected(assignments.items.map(item => item.id))
    } else {
      setSelected([])
    }
  }

  const handleProcessAssignment = () => {
    confirm({
      onType: 'danger',
      onText: 'Procesar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <QuestionCircleOutlined />,
      content: `¿Está seguro que desea enviar ${selected.length} encuestas? Esta operación no se puede revertir`,
      onOk: () => dispatch(processAssignments(selected))
    })
  }

  const handleDeleteAssignments = () => {
    confirm({
      onType: 'danger',
      onText: 'Eliminar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <ExclamationCircleOutlined />,
      content: `¿Está seguro que desea eliminar ${selected.length} encuestas? Esta operación no se puede revertir`,
      onOk: () => dispatch(deleteAssignments(selected))
    })
  }

  const noQuestionsAlert = () => {
    error({
      title: '¡Uh-oh!',
      content: 'La empresa no posee una encuesta con preguntas.'
    })
  }

  const surveyWithQuestions = () => {
    if (Object.keys(survey).length === 0 && survey.constructor === Object) { return false }
    return survey.questions.length !== 0
  }

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={asignmentsLoading || deleteIsLoading || processAssignmentIsLoading || processAssignmentsIsLoading || deleteAssignmentsIsLoading} />
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <div style={{ display: 'flex' }}>
                <Checkbox
                  color='primary' className='gx-icon-btn'
                  onClick={toggleSelectAll}
                  checked={selected?.length === assignments?.items?.length} i
                  indeterminate={selected.length > 0 && selected.length < assignments.items.length}
                />
                {
                  selected.length > 0 &&
                    <Row align='center' style={{ marginTop: 2.5 }}>
                      <div style={{ display: 'flex', flexGrow: 1 }}>
                        <Button type='text' icon={<i className='icon icon-menu-right gx-mr-2' />} onClick={handleProcessAssignment}>
                          Procesar Seleccionados
                        </Button>
                        <Button type='text' danger icon={<i className='icon icon-trash gx-mr-2' />} onClick={handleDeleteAssignments}>
                          Eliminar Seleccionados
                        </Button>
                      </div>
                    </Row>
                }
              </div>
              <CustomSearch
                form={searchForm}
                onChange={refresh}
                sortProperties={sortProperties}
                style={{ display: selected.length === 0 ? 'flex' : 'none' }}
              />
              {
                isOperator &&
                  <Row justify='end' style={{ flexGrow: 1, paddingRight: 30 }}>
                    <Tooltip title='Agregar Encuesta' placement='topLeft'>
                      <Button
                        size='large'
                        className='green-button'
                        shape='circle' type='primary'
                        icon={<PlusOutlined />}
                        aria-label='add' onClick={() => surveyWithQuestions() ? toggleModal() : noQuestionsAlert()}
                      />
                    </Tooltip>
                  </Row>
              }

            </div>
            <CustomScrollbars className='candidate-scroll'>
              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      No se encontraron encuestas con los filtros específicados
                    </div>
                  )
                  : data.map((assignment) =>
                    <AssignmentCell
                      assignment={assignment}
                      key={`${assignment.id}`}
                      onSelect={selectAssignment}
                      isSelected={isSelected}
                    />
                  )
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                disabled={selected.length > 0}
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={assignments?.total || 0}
                current={assignments?.page || 0}
                pageSize={assignments?.pageSize || 0}
                hideOnSinglePage={assignments.total === 0}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
      <Modal
        centered
        width={1024}
        title='Agregar Encuesta'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Registrar'
            type='primary'
            htmlType='submit'
            onClick={() => form.submit()}
            loading={saveIsLoading}
          >
            Registrar
          </Button>
        ]}
      >
        <AssignmentForm form={form} onFinish={saveAssignment} />
      </Modal>
    </div>
  )
}

export default Assignments
