import { extract, successState } from '../app/app.actions'
import { actionTypes } from './statistics.types'

const initialState = {
  company: { requests: [], results: [] },
  branch: { requests: [], results: [] },
  user: { requests: [], results: [] }
}

const StatisticsReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.COMPANY_STATISTICS):
      return {
        ...state,
        company: extract(data, initialState.company)
      }
    case successState(actionTypes.BRANCH_STATISTICS):
      return {
        ...state,
        branch: extract(data, initialState.branch)
      }
    case successState(actionTypes.USER_STATISTICS):
      return {
        ...state,
        user: extract(data, initialState.user)
      }
    case successState(actionTypes.CLEAR_STATISTICS):
      return initialState
    default:
      return state || initialState
  }
}

export default StatisticsReducer
