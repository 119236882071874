import React, { useEffect } from 'react'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectCompanySurveyQuestion } from '../../store/surveys/surveys.selector'
import { getSingleQuestion, updateCompanySurveyQuestion } from '../../store/surveys/surveys.actions'
import { Button, Form, Row, Tabs } from 'antd'
import QuestionForm from '../../components/Surveys/QuestionForm'
import { history } from '../../store'

const TabPane = Tabs.TabPane

const SingleQuestion = () => {
  const { questionId } = useParams()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const question = useSelector(selectCompanySurveyQuestion)

  const init = () => {
    dispatch(getSingleQuestion({ id: questionId }))
  }

  useEffect(init, [])

  useEffect(() => {
    if (question != null) {
      const opt = []
      question.options.map(o => opt.push({ option: o.text, id: o.id }))
      form.setFieldsValue({ question: question.text, type: question.type, range: question.limit, options: opt })
    } else {
      form.resetFields()
    }
  }, [form, question])

  const onFinish = ({ question, type, range, options }) => {
    dispatch(updateCompanySurveyQuestion({ id: questionId, text: question, type, limit: range, options: options }))
    history.push('/questionary')
  }

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <CustomScrollbars className='candidate-tabs-scroll'>
              <div style={{ padding: '28px' }}>
                <Tabs type='card'>
                  <TabPane tab='Pregunta' key='1'>
                    <QuestionForm question={question} form={form} onFinish={onFinish} questionOptions={question.options ? question.options : []} />
                    <Row justify='end' className='gx-pr-3'>
                      <Button type='button' className='ant-btn ant-btn-primary' onClick={() => form.submit()}>
                        Guardar Cambios
                      </Button>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </CustomScrollbars>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleQuestion
