import React from 'react'
import { Avatar, Tooltip } from 'antd'
import { randomHexColorByString } from '../../util/functions.utils'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import moment from 'moment'

const CandidateJobCell = ({ job, index, isProcessed, handleDelete, handleEdit }) => {
  let start = moment(job.startDate)
  const end = moment(job.endDate)

  const years = end.diff(start, 'years')
  start.add(years, 'years')
  const months = end.diff(start, 'months')
  start.add(months, 'months')
  const days = end.diff(start, 'days')
  const diff = years ? `${years} ${years === 1 ? 'año' : 'años'}, ${months} ${months === 1 ? 'mes' : 'meses'}`
    : months && days ? `${months} ${months === 1 ? 'mes' : 'meses'}, ${days} ${days === 1 ? 'día' : 'días'}`
      : months ? `${months} ${months === 1 ? 'mes' : 'meses'}` : `${days} ${days === 1 ? 'día' : 'días'}`

  start = moment(job.startDate)
  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar style={{ backgroundColor: randomHexColorByString(job.company), verticalAlign: 'middle' }} size='large'>
            #{index + 1}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <p className='gx-mb-1'>
            <span className='gx-contact-name'>{job.company}</span>
          </p>
          <p className='gx-mb-1'>
            <span> {job.position} </span>
          </p>
          <Row className='gx-text-muted'>
            <Col span={10}>
              <span className='gx-email gx-d-inline-block gx-mr-2'>
                Desde {start.format('DD/MM/YYYY')} hasta {job.endDate ? end.format('DD/MM/YYYY') : 'la fecha'}
              </span>
            </Col>
            <Col span={14}>
              <span className='gx-phone gx-d-inline-block'>({diff})</span>
            </Col>
          </Row>
        </div>
      </div>
      {
        !isProcessed &&
          <Row>
            <Col span={8} />
            <Col span={8}>
              <Tooltip title='Eliminar'>
                <i className='gx-icon-btn icon icon-trash' onClick={() => { handleDelete(job) }} />
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Editar'>
                <i onClick={() => handleEdit(job)} className='gx-icon-btn icon icon-edit' />
              </Tooltip>
            </Col>
          </Row>
      }
    </div>
  )
}

export default CandidateJobCell
