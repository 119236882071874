import { $http } from '../../util/http.utils'

class AuthService {
  login =
    (email, password) =>
      $http.post('/auth/login', { email, password })

  updateUser =
    ({ name, newPassword, oldPassword }) =>
      $http.put('/users', { name, newPassword, oldPassword })

  getRoles =
    () =>
      $http.get('/company/roles');
}

export default new AuthService()
