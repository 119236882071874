import BranchService from './branch.service'
import { actionTypes } from './branch.types'
import { executeAction } from '../app/app.actions'

export const searchBranches = ({ page = 1, pageSize = 10, sortOrder, sortProperty, searchProperty = 'name', last = 10, search, active, deleted }) => dispatch => {
  const process = () => BranchService.search({ active, deleted, last, page, pageSize, search, sortOrder, searchProperty, sortProperty })
  dispatch(executeAction(actionTypes.GET_BRANCHES, process))
}

export const getSingleBranch = ({ id }) => dispatch => {
  const process = () => BranchService.singleBranch({ id })
  dispatch(executeAction(actionTypes.GET_BRANCH, process))
}

export const updateBranch = ({ id, address, contactName, email, name, phone }) => dispatch => {
  const process = () => BranchService.updateBranch({ id, address, contactName, email, name, phone })
  dispatch(executeAction(actionTypes.UPDATE_BRANCH, process))
}

export const addBranch = ({ address, contactName, email, name, phone }) => dispatch => {
  const process = () => BranchService.newBranch({ address, contactName, email, name, phone })
  dispatch(executeAction(actionTypes.ADD_BRANCH, process))
}

export const clearBranches = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_BRANCH))
}
