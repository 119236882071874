import React, { useState } from 'react'
import { Avatar, Button, Modal, Tag, Timeline, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import { createFromIconfontCN, UserSwitchOutlined } from '@ant-design/icons'
import moment from 'moment'

const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/font_2419499_5kk5y6c6hnb.js'
  ]
})

const ProcessCandidateCell = ({ candidate, handleModal }) => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)
  const status = candidate.investigationDate ? 'En investigación' : 'Enviado'
  const color = candidate.investigationDate ? 'green' : 'orange'
  const icon = candidate.investigationDate ? 'icon-incognito' : 'icon-plane'

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Tooltip title={status}>
            <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size='large'>
              <IconFont type={icon} />
            </Avatar>
          </Tooltip>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row align='middle'>
            <Col span={3}>
              <Tooltip title='Folio'>
                <span className='gx-text-truncate'>#{candidate.id} </span>
              </Tooltip>
            </Col>
            <Col span={3}>
              <Tooltip title='Fecha de envío'>
                <span className='gx-text-truncate'>{moment(candidate.sentDate).format('DD/MM/yyyy')} </span>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Nombre del Candidato'>
                <span className='gx-text-uppercase'>{candidate.name}</span>
              </Tooltip>
            </Col>
            <Col span={5}>
              <Tooltip title='Usuario'>
                <span className='gx-text-muted' style={{ fontSize: 12 }}>{candidate.createdByName}</span>
              </Tooltip>
            </Col>
            <Col span={5}>
              <Tag color={color} style={{ width: '120px', textAlign: 'center', marginTop: 5 }}>{status}</Tag>
            </Col>
          </Row>
        </div>
        <div className='gx-module-contact-right'>
          <Row>
            <Col span={8}>
              <Tooltip title='Ver detalles'>
                <Link to={`/candidate/${candidate.id}`}>
                  <i className='gx-icon-btn icon icon-eye' />
                </Link>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title='Historial'>
                <i onClick={toggleModal} className='gx-icon-btn icon icon-timeline-new' />
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title='Reasignar'>
                <span onClick={() => handleModal(candidate)}>
                  <UserSwitchOutlined />
                </span>
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        title='Historial'
        visible={showModal}
        onCancel={toggleModal}
        footer={[
          <Button
            key='ok'
            type='primary'
            onClick={toggleModal}
          > Ok
          </Button>
        ]}
      >
        <div>
          <Timeline>
            <Timeline.Item color='green'>
              Candidato creado: {moment(candidate.createdAt).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color='green'>
              Enviado a investigacion: {moment(candidate.sentDate).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color={candidate.investigationDate ? 'green' : 'orange'}>
              {
                candidate.investigationDate
                  ? `Inicio de Investigación: ${moment(candidate.investigationDate).format('DD/MM/yyyy')}`
                  : 'Inicio de Investigación: Pendiente'
              }
            </Timeline.Item>
            <Timeline.Item color='grey'>Resultados: Pendiente </Timeline.Item>
          </Timeline>
        </div>
      </Modal>
    </div>
  )
}

export default ProcessCandidateCell
