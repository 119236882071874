import React from 'react'
import { Col, Row, Typography } from 'antd'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { renderActiveShape } from '../../util/charts.utils'
import { BarChartOutlined, PieChartOutlined } from '@ant-design/icons'

const { Title } = Typography

const Statistics = ({ requests = [], results = [], company = false }) => {
  const getTotal = ({ apto = 0, aptoCR = 0, noApto = 0 } = {}) => {
    return apto + aptoCR + noApto
  }

  const getRequestTotal = () => requests.reduce((acc, current) => acc + current.value, 0)

  return (
    <>
      <Title level={5} className='gx-ml-3'>Fecha del último corte: 01/01/2021</Title>
      <Row>
        <Col span={12}>
          {
            results.length === 0
              ? (
                <Row align='middle' justify='center' style={{ height: 390 }}>
                  <Col className='gx-text-center'>
                    <BarChartOutlined style={{ fontSize: 48 }} />
                    <Title level={5}>Sin datos</Title>
                  </Col>
                </Row>
              ) : (
                <ResponsiveContainer width='100%' height={390}>
                  <BarChart
                    data={results}
                    margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
                  >
                    <XAxis dataKey='name' />
                    <YAxis />
                    <CartesianGrid strokeDasharray='3 3' />
                    <Tooltip wrapperStyle={{ backgroundColor: 'white', border: '1px solid black' }} labelStyle={{ color: 'black' }} />
                    <Legend align='center' verticalAlign='top' height={50} />
                    <Bar dataKey='apto' name='Aptos' stackId='a' fill='#038FDD' />
                    <Bar dataKey='aptoCR' name='Con Reserva' stackId='a' fill='orange' />
                    <Bar dataKey='noApto' name='No Aptos' stackId='a' fill='red'>
                      <LabelList position='top' valueAccessor={getTotal} />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )
          }
          <Title level={5} className='gx-ml-3 gx-text-center'>Resultados {company ? 'por sucursal' : ''}</Title>
        </Col>
        <Col span={12}>
          {
            requests.length === 0 || getRequestTotal() === 0
              ? (
                <Row align='middle' justify='center' style={{ height: 390 }}>
                  <Col className='gx-text-center'>
                    <PieChartOutlined style={{ fontSize: 48 }} />
                    <Title level={5}>Sin datos</Title>
                  </Col>
                </Row>
              ) : (
                <ResponsiveContainer width='100%' height={390}>
                  <PieChart>
                    <Pie
                      label={renderActiveShape}
                      outerRadius={120}
                      innerRadius={70}
                      dataKey='value'
                      nameKey='name'
                      cx='50%' cy='50%'
                      data={requests}
                      isAnimationActive
                    >
                      {requests.map(({ name, color }) => <Cell key={name} fill={color} />)}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              )
          }
          <Title level={5} className='gx-ml-3 gx-text-center'>Solicitudes Enviadas</Title>
        </Col>
      </Row>
    </>
  )
}
export default Statistics
