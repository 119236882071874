import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Auth from './Auth'
import E404 from '../pages/Error/E404'
import Login from '../pages/Auth/Login'
import Dashboard from '../pages/Dashboard/Dashboard'
import DraftCandidates from '../pages/Candidates/DraftCandidates'
import ProcessCandidates from '../pages/Candidates/ProcessCandidates'
import CompletedCandidates from '../pages/Candidates/CompletedCandidates'
import SingleCandidate from '../pages/Candidates/SingleCandidate'
import Users from '../pages/Users/Users'
import Branches from '../pages/Branches/Branches'
import SingleBranch from '../pages/Branches/SingleBranch'
import Zones from '../pages/Zones/Zones'
import UserProfile from '../pages/Users/UserProfile'
import SingleZone from '../pages/Zones/SingleZone'
import Invoices from '../pages/Invoices/Invoices'
import SingleCompany from '../pages/Company/SingleCompany'
import Questionary from '../pages/Surveys/Questionary'
import Assignments from "../pages/Surveys/Assignments";
import SingleQuestion from "../pages/Surveys/SingleQuestion";
import SingleAssignment from "../pages/Surveys/SingleAssignment";
import ProcessAssignments from "../pages/Surveys/ProcessAssignments";
import CompletedAssignments from "../pages/Surveys/CompletedAssignments";
import AssignmentAnswers from "../pages/Surveys/AssignmentAnswers";

const Routes = () => {
  return (
    <Switch>

      <Route path='/zones/zone'>
        <Auth Component={SingleZone} />
      </Route>

      <Route path='/zones'>
        <Auth Component={Zones} />
      </Route>

      <Route path='/users'>
        <Auth Component={Users} />
      </Route>

      <Route path='/surveys/question/:questionId'>
        <Auth Component={SingleQuestion} />
      </Route>

      <Route path='/surveys/process/assignments'>
        <Auth Component={ProcessAssignments} />
      </Route>

      <Route path='/surveys/completed/assignments'>
        <Auth Component={CompletedAssignments} />
      </Route>

      <Route path='/surveys/assignments/:assignmentId'>
        <Auth Component={SingleAssignment} />
      </Route>

      <Route path='/surveys/completed/:assignmentId'>
        <Auth Component={AssignmentAnswers} />
      </Route>

      <Route path='/surveys/assignments'>
        <Auth Component={Assignments} />
      </Route>

      <Route path='/questionary'>
        <Auth Component={Questionary} />
      </Route>

      <Route path='/profile'>
        <Auth Component={UserProfile} />
      </Route>

      <Route path='/login' component={Login} />

      <Route path='/in-process/candidate/:candidateId'>
        <Auth Component={SingleCandidate} />
      </Route>

      <Route path='/in-process'>
        <Auth Component={ProcessCandidates} />
      </Route>

      <Route path='/finished'>
        <Auth Component={CompletedCandidates} />
      </Route>

      <Route path='/candidate/:candidateId'>
        <Auth Component={SingleCandidate} />
      </Route>

      <Route path='/drafts'>
        <Auth Component={DraftCandidates} />
      </Route>

      <Route path='/branch/:branchId'>
        <Auth Component={SingleBranch} />
      </Route>

      <Route path='/branches'>
        <Auth Component={Branches} />
      </Route>

      <Route path='/invoices'>
        <Auth Component={Invoices} />
      </Route>

      <Route path='/company'>
        <Auth Component={SingleCompany} />
      </Route>

      <Route path='/' exact>
        <Auth Component={Dashboard} />
      </Route>

      <Route component={E404} />
    </Switch>
  )
}
export default Routes
