export const actionTypes = {
  GET_BRANCH_USERS: 'branch/users/get',
  ADD_USER: 'branch/users/add',
  UPDATE_USERS: 'branch/users/update',
  DELETE_USER: 'branch/users/delete',
  RESET_PASSWORD: 'users/reset/password',
  TOGGLE_OPERATOR: 'branch/users/toggle/operator',
  TOGGLE_SUPERVISOR: 'branch/users/toggle/supervisor',
  TOGGLE_ADMIN: 'branch/users/toggle/admin',
  REMOVE_FROM_COMPANY: 'users/remove/all/roles',
  CLEAR_USERS: 'users/clear'
}
