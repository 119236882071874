import axios from 'axios'
import { API_URL } from '../app.settings'
import { store } from '../store/'
import { logout, showLogout } from '../store/auth/logout.actions'
import { selectCurrentBranch, selectCurrentCompany, selectToken } from '../store/auth/auth.selectors'
import { clearAssignments } from '../store/assignments/assignments.actions'
import { clearBranches } from '../store/branches/branch.actions'
import { clearCandidates } from '../store/candidates/candidates.actions'
import { clearCompany } from '../store/company/company.actions'
import { clearInvoice } from '../store/invoice/invoice.actions'
import { clearSurveys } from '../store/surveys/surveys.actions'
import { clearStatistics } from '../store/statistics/statistics.actions'

const instance = axios.create({
  baseURL: API_URL,
  timeout: 10000
})

instance.interceptors.request.use(
  async config => {
    const state = store.getState()
    const accessToken = selectToken(state)
    const currentCompany = selectCurrentCompany(state)
    const currentBranch = selectCurrentBranch(state)

    if (accessToken) {
      config.headers.Authorization = `bearer ${accessToken}`
    }
    if (currentCompany) {
      config.headers['X-RHCompany'] = currentCompany.id
    }
    if (currentBranch) {
      config.headers['X-RHBranch'] = currentBranch.id
    }

    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && error.response.config.url !== '/auth/login') {
      // alert('La sesión ha expirado o no tiene permisos para ejecutar esta acción')
      store.dispatch(clearSurveys())
      store.dispatch(clearInvoice())
      store.dispatch(clearCompany())
      store.dispatch(clearCandidates())
      store.dispatch(clearBranches())
      store.dispatch(clearAssignments())
      store.dispatch(clearStatistics())
      store.dispatch(showLogout())
      store.dispatch(logout())
    }
    return Promise.reject(error)
  })

export const downloadFile = async (url, params) => {
  const response = await $http.get(url, { responseType: 'blob', headers: { accept: 'application/octet-stream' }, params })
  const data = response.data
  if (!(data instanceof Blob)) return

  const filename = response.headers['x-file-name']
  const blob = new Blob([data], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
}

export const $http = instance
