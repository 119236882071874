import { executeAction } from '../app/app.actions'
import { actionTypes } from './invoice.types'
import CompanyService from './invoice.service'

export const searchInvoices = ({ companyId, active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty, from, to }) => dispatch => {
  const process = () => CompanyService.searchInvoices({ companyId, active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty, from, to })
  dispatch(executeAction(actionTypes.SEARCH_INVOICES, process))
}

export const downLoadInvoice = ({ companyId, invoiceId }) => dispatch => {
  const process = async () => CompanyService.downloadInvoice({ companyId, invoiceId })
  dispatch(executeAction(actionTypes.DOWNLOAD_INVOICE, process))
}

export const clearInvoice = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_INVOICE))
}
