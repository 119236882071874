import React from 'react'
import { Modal, Tooltip } from 'antd'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import { Link } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { deleteQuestion } from '../../store/surveys/surveys.actions'

const types = [
  { label: 'Opcion Unica', property: 'radio' },
  { label: 'Opcion Multiple', property: 'check-box' },
  { label: 'Pregunta abierta', property: 'text' },
  { label: 'Rango', property: 'range' }
]

const { confirm } = Modal

const QuestionCell = ({ question, index, changePosition }) => {
  const dispatch = useDispatch()

  const findByType = (type) => {
    for (let i = 0; i < types.length; i++) {
      if (types[i].property === type) { return types[i].label }
    }
    return ''
  }

  const confirmDelete = () => {
    confirm({
      okType: 'danger',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <ExclamationCircleOutlined />,
      content: 'Desea eliminar esta pregunta? Esta operación no se puede revertir.',
      onOk: () => dispatch(deleteQuestion(question.id))
    })
  }

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div style={{ width: '50px', display: 'flex', justifyContent: 'center' }}>#{question.position + 1 || 1}</div>
        <Tooltip title='Subir' onClick={() => changePosition(question.id, index, -1)}>
          <i className='gx-icon-btn icon icon-menu-up' />
        </Tooltip>
        <Tooltip title='Bajar' onClick={() => changePosition(question.id, index, 1)}>
          <i className='gx-icon-btn icon icon-menu-down' />
        </Tooltip>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row>
            <Col span={3}>
              <Tooltip title='Tipo'>
                <span className='gx-text-uppercase'>{findByType(question.type)}</span>
              </Tooltip>
            </Col>
            <Col span={15}>
              <Tooltip title='Pregunta'>
                <div className='gx-text-truncate'>
                  <span>{question.text}</span>
                </div>
              </Tooltip>
            </Col>
            <Col span={6}>
              {question.type === 'range' &&
                <Tooltip title='Rango'>
                  <span className='gx-text-muted'> Rango: 1 - {question.limit}</span>
                </Tooltip>}
              {(question.type === 'radio' || question.type === 'check-box') &&
                <Tooltip title='Opciones'>
                  <span className='gx-text-muted'> Opciones: {question.options.length}</span>
                </Tooltip>}

            </Col>
          </Row>
        </div>
        <div className='gx-module-contact-right' style={{ maxWidth: '100px' }}>
          <Row>
            <Col span={12}>
              <Tooltip title='Eliminar' onClick={confirmDelete}>
                <i className='gx-icon-btn icon icon-trash' />
              </Tooltip>
            </Col>
            <Col span={12}>
              <Tooltip title='Editar'>
                <Link to={`/surveys/question/${question.id}`}><i className='gx-icon-btn icon icon-edit' /></Link>
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default QuestionCell
