import SurveyService from './surveys.service'
import { actionTypes } from './surveys.types.js'
import { executeAction } from '../app/app.actions'

export const getCompanySurvey = () => dispatch => {
  const process = () => SurveyService.compSurvey({})
  dispatch(executeAction(actionTypes.GET_SURVEY, process))
}

export const addCompanySurveyQuestion = ({ text, type, limit, options, position }) => dispatch => {
  const process = async () => {
    const result = await SurveyService.addQuestion({ text, type, limit, options, position })
    dispatch(getCompanySurvey())
    return result
  }
  dispatch(executeAction(actionTypes.ADD_QUESTION, process))
}

export const updateCompanySurveyQuestion = ({ id, text, type, limit, options }) => dispatch => {
  const process = async () => {
    const result = await SurveyService.updateQuestion({ id, text, type, limit, options })
    dispatch(getCompanySurvey())
    return result
  }
  dispatch(executeAction(actionTypes.UPDATE_QUESTION, process))
}

export const getSingleQuestion = ({ id }) => dispatch => {
  const process = () => SurveyService.singleQuestion({ id })
  dispatch(executeAction(actionTypes.GET_QUESTION, process))
}

export const deleteQuestion = (id) => (dispatch) => {
  const data = []
  data.push(id)
  const process = () => SurveyService.deleteQuestion(data)
  dispatch(executeAction(actionTypes.DELETE_QUESTION, process))
}

export const addQuestionOption = ({ questionId, text }) => (dispatch) => {
  const process = async () => {
    const result = await SurveyService.addQuestionOption({ questionId, text })
    dispatch(getSingleQuestion({ id: questionId }))
    return result
  }
  dispatch(executeAction(actionTypes.ADD_QUESTION_OPTION, process))
}

export const deleteQuestionOption = ({ questionId, optionId }) => (dispatch) => {
  const process = async () => {
    const result = await SurveyService.deleteQuestionOption({ questionId, optionId })
    dispatch(getSingleQuestion({ id: questionId }))
    return result
  }
  dispatch(executeAction(actionTypes.DELETE_QUESTION_OPTION, process))
}

export const updatePositions = (list) => (dispatch) => {
  const process = async () => {
    const result = await SurveyService.updatePositions(list)
    dispatch(getCompanySurvey())
    return result
  }
  dispatch(executeAction(actionTypes.DELETE_QUESTION_OPTION, process))
}

export const clearSurveys = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_SURVEYS))
}
