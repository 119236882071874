import React, { useState } from 'react'
import { Avatar, Col, Modal, Row, Tag, Timeline, Tooltip } from 'antd'
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { downloadCandidateReport } from '../../store/candidates/candidates.actions'

const getColorByStatus = (status) => {
  switch (status) {
    case 1:
      return '#87D068'
    case 2:
      return '#F3D46E'
    case 3:
      return '#FF5A87'
    default:
      return '#FFFFFF'
  }
}

const CompletedCandidateCell = ({ candidate }) => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()

  const getTagByStatus = (status) => {
    switch (status) {
      case 1:
        return (
          <Tag color='#87D068' className='status-tag'>APTO</Tag>
        )
      case 2:
        return (
          <Tag color='#F3D46E' className='status-tag'>APTO C/R</Tag>
        )
      case 3:
        return (
          <Tag color='#FF5A87' className='status-tag'>NO APTO</Tag>
        )
      default:
        return (
          <Tag color='#FFFFFF' className='status-tag'>SIN INFORMACION</Tag>
        )
    }
  }

  const downloadReport = () => {
    dispatch(downloadCandidateReport({ candidateId: candidate.id }))
  }

  const toggleModal = () => { setShowModal(!showModal) }

  return (
    <div className='cell-border'>
      <div className='gx-contact-item' style={{ cursor: 'pointer' }}>
        <div className='gx-module-list-icon'>
          <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
            <Avatar style={{ backgroundColor: getColorByStatus(candidate.result), verticalAlign: 'middle' }} size='large'>
              {candidate.name[0].toUpperCase()}
            </Avatar>
          </div>
        </div>
        <div className='gx-module-list-info gx-contact-list-info'>
          <div className='gx-module-contact-content'>
            <Row align='middle'>
              <Col span={3}>
                <Tooltip title='Folio'>
                  <span className='gx-text-truncate'>#{candidate.id} </span>
                </Tooltip>
              </Col>
              <Col span={3}>
                <Tooltip title='Fecha de entrega'>
                  <span className='gx-text-truncate'>{moment(candidate.sentDate).format('DD/MM/yyyy')} </span>
                </Tooltip>
              </Col>
              <Col span={10}>
                <Tooltip title='Nombre del candidato'>
                  <span className='gx-text-uppercase'>{candidate.name}</span>
                </Tooltip>
              </Col>
              <Col span={5}>
                <Tooltip title='Usuario'>
                  <span className='gx-text-muted' style={{ fontSize: 12 }}>{candidate.createdByName}</span>
                </Tooltip>
              </Col>
              <Col span={3}>
                {getTagByStatus(candidate.result)}
              </Col>
            </Row>
          </div>
          <div className='gx-module-contact-right'>
            <Row>
              <Col span={8} />
              <Col span={4}>
                <br />
                <Tooltip title='Descargar' onClick={downloadReport}>
                  <DownloadOutlined />
                </Tooltip>
              </Col>
              <Col span={4}>
                <Tooltip title='Historial' className='flex-color-button'>
                  <i onClick={toggleModal} className='gx-icon-btn icon icon-timeline-new flex-icon-button-3' />
                </Tooltip>
              </Col>
              <Col span={4}>
                <Tooltip title='Ver detalles'>
                  <Link to={`/candidate/${candidate.id}`}>
                    <i className='gx-icon-btn icon icon-eye' />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal
        title='Historial'
        visible={showModal}
        onOk={toggleModal}
        onCancel={toggleModal}
      >
        <div>
          <Timeline>
            <Timeline.Item color='green'>
              Candidato creado: {moment(candidate.createdAt).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color='green'>
              Enviado a investigacion: {moment(candidate.sentDate).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color='green'>
              Inicio de Investigación: {moment(candidate.investigationDate).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color='green'>
              Resultados: {moment(candidate.finishDate).format('DD/MM/yyyy')}
            </Timeline.Item>
          </Timeline>
        </div>
      </Modal>
    </div>
  )
}

export default CompletedCandidateCell
