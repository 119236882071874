import React from 'react'
import { Avatar, Tooltip } from 'antd'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import { Link } from 'react-router-dom'

const BranchCell = ({ branch }) => {
  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar style={{ backgroundColor: 'rgb(110, 142, 183)', verticalAlign: 'middle' }} size='large'>
            {branch.name[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <p className='gx-mb-1'>
            <span className='gx-text-truncate gx-contact-name'>{branch.name} </span>
          </p>

          <div className='gx-text-muted'>
            <span className='gx-email-block gx-mr-2'>
              {branch.address}
            </span>
            <span className='gx-toolbar-separator'>&nbsp;</span>
            <span className='gx-email-block gx-mr-2'>
              {branch.phone}
            </span>
          </div>
        </div>
      </div>
      <div className='gx-module-contact-right'>
        <Row>
          <Col span={8}>
            <Tooltip title='Editar'>
              <Link to={`/branch/${branch.id}`}><i className='gx-icon-btn icon icon-edit' /></Link>
            </Tooltip>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BranchCell
