import AssignmentService from './assignments.service'
import { actionTypes } from './assignments.types'
import { executeAction } from '../app/app.actions'

export const searchAssignment = ({ page = 1, pageSize = 10, sortOrder, sortProperty, last = 10, from, to, search, active, deleted, status, pending = true, investigating = true, result = 0, byUser }) => dispatch => {
  const process = () => AssignmentService.search({ page, pageSize, sortOrder, sortProperty, last, from, to, search, active, deleted, status, pending, investigating, result, byUser })
  dispatch(executeAction(actionTypes.SEARCH_ASSIGNMENT, process))
}

export const addAssignment = ({ name, contactPhone, position, comments, start, end }) => dispatch => {
  const process = async () => {
    return await AssignmentService.addAssignment({ name, contactPhone, position, comments, start, end })
  }
  dispatch(executeAction(actionTypes.ADD_ASSIGNMENT, process))
}

export const editAssignment = ({ id, name, contactPhone, position, comments, start, end }) => dispatch => {
  const process = () => AssignmentService.editAssignment({ id, name, contactPhone, position, comments, start, end })
  dispatch(executeAction(actionTypes.EDIT_ASSIGNMENT, process))
}

export const getSingleAssignment = (id) => dispatch => {
  const process = () => AssignmentService.singleAssignment({ id })
  dispatch(executeAction(actionTypes.SINGLE_ASSIGNMENT, process))
}

export const deleteAssignment = (id) => (dispatch) => {
  const process = () => AssignmentService.deleteAssignment({ id })
  dispatch(executeAction(actionTypes.DELETE_ASSIGNMENT, process))
}

export const deleteAssignments = ids => dispatch => {
  const process = () => AssignmentService.deleteAssignments(ids)
  dispatch(executeAction(actionTypes.DELETE_ASSIGNMENTS, process))
}

export const processAssignment = (id) => (dispatch) => {
  const process = () => AssignmentService.processAssignment({ id })
  dispatch(executeAction(actionTypes.PROCESS_ASSIGNMENT, process))
}

export const processAssignments = (ids) => dispatch => {
  const process = () => AssignmentService.processAssignments(ids)
  dispatch(executeAction(actionTypes.PROCESS_ASSIGNMENTS, process))
}

export const reassignAssignment = ({ assignmentId, userId }) => dispatch => {
  const process = () => AssignmentService.reassignAssignment({ assignmentId, userId })
  dispatch(executeAction(actionTypes.REASSIGN_ASSIGNMENT, process))
}

export const getAssignmentAnswers = ({ assignmentId }) => dispatch => {
  const process = () => AssignmentService.assignmentAnswers({ assignmentId })
  dispatch(executeAction(actionTypes.ASSIGNMENT_ANSWERS, process))
}

export const downloadAssignmentReport = ({ assignmentId }) => dispatch => {
  const process = () => AssignmentService.getReport({ assignmentId })
  dispatch(executeAction(actionTypes.ASSIGNMENT_REPORT, process))
}

export const clearAssignments = () => dispatch => {
  dispatch(executeAction(actionTypes.ASSIGNMENTS_CLEAR))
}

//= ===================================================================//
export const getAssignmentsX = () => dispatch => {
  const process = () => AssignmentService.getAssignments({})
  dispatch(executeAction(actionTypes.GET_ASSIGNMENTS, process))
}

export const getSingleAssignmentX = (id) => dispatch => {
  const process = () => AssignmentService.getSingleAssignment({ id })
  dispatch(executeAction(actionTypes.GET_SINGLE_ASSIGNMENTS, process))
}

export const addAssignmentX = ({ nombre, contactPhone, position, comments, sDate, eDate }) => dispatch => {
  const process = async () => {
    return await AssignmentService.addAssignment({ nombre, contactPhone, position, comments, sDate, eDate })
  }
  dispatch(executeAction(actionTypes.ADD_ASSIGNMENT, process))
}

export const editAssignmentX = ({ id, nombre, contactPhone, position, comments, sDate, eDate }) => dispatch => {
  const process = () => AssignmentService.updateAssignment({ id, nombre, contactPhone, position, comments, sDate, eDate })
  dispatch(executeAction(actionTypes.EDIT_ASSIGNMENT, process))
}

export const deleteAssignmentsX = (id) => dispatch => {
  const process = () => AssignmentService.deleteAssignment({ id })
  dispatch(executeAction(actionTypes.DELETE_ASSIGNMENT, process))
}
