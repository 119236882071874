export const actionTypes = {
  ADD_CANDIDATE: 'candidate/add',
  ADD_CANDIDATE_JOBS: 'candidate/jobs/add',
  CANDIDATE_JOBS: 'candidate/jobs/get',
  REASSIGN_CANDIDATE: 'candidate/reassign',
  DELETE_CANDIDATE: 'candidate/delete',
  DELETE_CANDIDATES: 'candidates/delete',
  DELETE_CANDIDATE_JOB: 'candidate/jobs/delete',
  DOWNLOAD_REPORT: 'candate/report',
  EDIT_CANDIDATE: 'candidate/edit',
  EDIT_CANDIDATE_JOB: 'candidate/jobs/edit',
  PROCESS_CANDIDATE: 'candidate/process',
  PROCESS_CANDIDATES: 'candidates/process',
  SEARCH_CANDIDATES: 'candidates/search',
  SINGLE_CANDIDATE: 'candidate/get/single',
  CLEAR_CANDIDATES: 'candidate/clear'
}
