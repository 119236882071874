import { executeAction } from '../app/app.actions'
import { actionTypes } from './auth.types'
import { clearStatistics } from '../statistics/statistics.actions'
import { clearSurveys } from '../surveys/surveys.actions'
import { clearInvoice } from '../invoice/invoice.actions'
import { clearCompany } from '../company/company.actions'
import { clearCandidates } from '../candidates/candidates.actions'
import { clearBranches } from '../branches/branch.actions'
import { clearAssignments } from '../assignments/assignments.actions'

export const showLogout = () => dispatch => {
  const process = () => false
  dispatch(executeAction(actionTypes.SHOW_LOGOUT, process))
}

export const logout = () => dispatch => {
  dispatch(clearSurveys())
  dispatch(clearInvoice())
  dispatch(clearCompany())
  dispatch(clearCandidates())
  dispatch(clearBranches())
  dispatch(clearAssignments())
  dispatch(clearStatistics())
  dispatch(clearStatistics())
  dispatch(executeAction(actionTypes.LOGOUT))
}
