import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Modal } from 'antd'
import { getCompany, updateCompany } from '../../store/company/company.actions'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/company/company.types'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { UNEXPECTED_OOPS } from '../../util/messages.utils'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import SingleCompanyForm from '../../components/Company/CompanyForm'
import { selectCompany } from '../../store/company/company.selector'

const { error, success } = Modal

const SingleCompany = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const company = useSelector(selectCompany)
  const [updateIsLoading, updateFinished] = useIsLoading([actionTypes.UPDATE_COMPANY])
  const [updateError, updateHasError] = useHasErrors([actionTypes.UPDATE_COMPANY])

  const onFinish = ({ address, contactName, email, name, phone }) => {
    dispatch(updateCompany({ address, contactName, email, name, phone }))
  }

  const init = () => {
    dispatch(getCompany())
  }
  useEffect(init, [])

  useEffect(() => {
    dispatch(updateTopBarTitle(company?.name))
  }, [company.name, dispatch])

  useEffect(() => {
    if (updateFinished) {
      if (updateHasError) {
        error({
          title: '¡Uh-oh!',
          content: updateError.message || UNEXPECTED_OOPS
        })
      } else {
        success({
          title: '¡Éxito!',
          content: 'Empresa guardada correctamente'
        })
      }
    }
  }, [updateFinished, updateHasError, updateError.message])

  return (
    <div className='gx-main-content'>
      <div className='gx-app-module'>
        <div className='gx-module-box-content'>
          <div style={{ padding: '28px' }}>
            <SingleCompanyForm company={company} form={form} onFinish={onFinish} />
            <br />
            <div className='gx-text-right'>
              <Button
                key='Registrar'
                type='primary'
                onClick={() => form.submit()} loading={updateIsLoading}
              >
                Guardar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleCompany
