import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Popover, Radio, Row, Tooltip } from 'antd'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import QuestionForm from '../../components/Surveys/QuestionForm'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import { actionTypes } from '../../store/surveys/surveys.types'
import { useDispatch, useSelector } from 'react-redux'
import { addCompanySurveyQuestion, getCompanySurvey, updatePositions } from '../../store/surveys/surveys.actions'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { selectCompanySurvey } from '../../store/surveys/surveys.selector'
import QuestionCell from '../../components/Surveys/QuestionCell'
import FilterOutlined from '@ant-design/icons/lib/icons/FilterOutlined'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { UNEXPECTED_OOPS } from '../../util/messages.utils'

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginRight: '30px'
}

const { success, error } = Modal

const Questionary = () => {
  const survey = useSelector(selectCompanySurvey)

  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [options, setOptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [filter, setFilter] = useState('')
  const [type, setType] = useState('ALL')

  const [surveyLoading] = useIsLoading([actionTypes.GET_SURVEY])
  const [, deleteQuestionIsFinished] = useIsLoading([actionTypes.DELETE_QUESTION])
  const [, addQuestionIsFinished] = useIsLoading([actionTypes.ADD_QUESTION])
  const [saveQuestionError, saveQuestionHasError] = useHasErrors([actionTypes.ADD_QUESTION])
  const [updateQuestionLoading, updateQuestionLoadingFinished] = useIsLoading([actionTypes.UPDATE_QUESTION])
  const [, updateQuestionHasError] = useHasErrors([actionTypes.UPDATE_QUESTION])
  const [showingAlert, setShowingAlert] = useState(false)

  useEffect(() => {
    // console.log("deleted finished")
    if (deleteQuestionIsFinished) {
      dispatch(getCompanySurvey())
    }
  }, [deleteQuestionIsFinished, dispatch])

  useEffect(() => {
    if (addQuestionIsFinished) {
      if (saveQuestionHasError) {
        error({
          title: '¡Uh-oh!',
          content: saveQuestionError.message || UNEXPECTED_OOPS
        })
      } else {
        if (!showingAlert) {
          success({
            title: '¡Exito!',
            content: 'Pregunta agregada con exito',
            onOk: () => setShowingAlert(false)
          })
        }
        setShowModal(false)
        form.resetFields()
        setShowingAlert(true)
      }
    }
  }, [addQuestionIsFinished, form, saveQuestionError, saveQuestionHasError, showingAlert])

  useEffect(() => {
    if (updateQuestionLoadingFinished) {
      if (updateQuestionHasError) {
        error({
          title: '¡Uh-oh!',
          content: saveQuestionError.message || UNEXPECTED_OOPS
        })
      } else {
        if (!showingAlert) {
          success({
            title: '¡Exito!',
            content: 'Pregunta editada con exito',
            onOk: () => setShowingAlert(false)
          })
        }
        setShowModal(false)
        form.resetFields()
        setShowingAlert(true)
      }
    }
  }, [form, saveQuestionError.message, showingAlert, updateQuestionHasError, updateQuestionLoading, updateQuestionLoadingFinished])

  const init = () => {
    dispatch(updateTopBarTitle('Cuestionario'))
    dispatch(getCompanySurvey())
  }

  useEffect(init, [])

  const toggleModal = () => {
    setShowModal(!showModal)
  }
  const setUpdateQuestions = (list) => {
    setOptions(list)
  }

  const saveQuestion = ({ question, type, range }) => {
    const opt = []
    if (options) {
      options.map(o => opt.push(o.text))
    }
    if ((type === 'check-box' && options.length === 0) || (type === 'radio' && options.length === 0)) {
      return
    }
    dispatch(addCompanySurveyQuestion({
      text: question,
      type,
      limit: range,
      options: opt,
      position: survey.questions.length
    }))
  }

  const changePosition = (answerId, index, val) => {
    const nowQuestions = survey?.questions.sort((a, b) => (a.position > b.position) ? 1 : -1)
    nowQuestions.forEach((a, i) => {
      a.position = i
    })
    const answerIndex = nowQuestions.findIndex(a => a.id === answerId)
    if (answerIndex < 0) {
      return
    }
    if (index + val > (nowQuestions.length - 1) || index + val < 0) {
      return
    }
    const pos = nowQuestions.splice(answerIndex, 1)[0]
    nowQuestions.splice(answerIndex + val, 0, pos)
    nowQuestions.forEach((a, i) => {
      a.position = i
    })
    const list = []
    nowQuestions.map(a => list.push({ id: a.id, position: a.position }))
    dispatch(updatePositions(list))
  }

  const filterText = (e) => {
    setFilter(e.target.value.toLowerCase())
  }

  const getFilters = () => {
    return (
      <Row className='gx-p-2'>
        <div style={{ marginTop: '20px' }} />
        <Col span={12}>
          <Radio.Group onChange={e => setType(e.target.value)} value={type}>
            <Radio value='ALL' style={radioStyle}>Todos</Radio>
            <Radio value='radio' style={radioStyle}>Unica</Radio>
            <Radio value='check-box' style={radioStyle}>Multiple</Radio>
            <Radio value='text' style={radioStyle}>Pregunta directa</Radio>
            <Radio value='range' style={radioStyle}>Rango</Radio>
          </Radio.Group>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={surveyLoading} />
            <div className='gx-module-box-topbar flex-header'>
              <div style={{ flexGrow: 1 }}>
                <div>
                  <span className='gx-search-icon gx-pointer'><i className='icon icon-search' /></span>
                  <Input
                    style={{ width: '50%', marginLeft: '10px' }}
                    placeholder='Buscar' type='search'
                    onChange={filterText}
                  />
                </div>
              </div>
              <div style={{ borderLeft: '1px solid grey', height: 35, marginLeft: 15, marginRight: 15 }} />
              <Tooltip title='Filtrar por'>
                <Popover content={getFilters()} title='Filtrar Por' trigger='click' placement='bottomRight'>
                  <Button style={{ marginTop: '10px' }} icon={<FilterOutlined />} />
                </Popover>
              </Tooltip>
              <div className='separation-search-bar'>
                <Button
                  className='gx-btn-block ant-btn green-button flex-color-button'
                  style={{ maxWidth: '170px', marginTop: '10px' }} type='primary' aria-label='add'
                  onClick={() => { toggleModal() }}
                >
                  <i className='icon icon-add gx-mr-2 flex-icon-button-7' />
                  <span>Agregar Pregunta</span>
                </Button>
              </div>
            </div>
            <br />
            <CustomScrollbars className='candidate-scroll'>
              {
                (survey && survey.questions && survey.questions.length > 0)
                  ? survey.questions
                    .filter((q) => {
                      return (q.text.toLowerCase().includes(filter) && (q.type === type || type === 'ALL'))
                    })
                    .sort((a, b) => (a.position > b.position) ? 1 : -1)
                    .map((q, index) => <QuestionCell question={q} key={q.id} index={index} changePosition={changePosition} />)
                  : (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      Sin preguntas activas en el cuestionario
                    </div>
                  )
              }
            </CustomScrollbars>
          </div>
        </div>
      </div>
      <Modal
        centered
        width={1024}
        title='Agregar Pregunta'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Agregar'
            type='primary'
            htmlType='submit'
            onClick={() => form.submit()}
          >
            Agregar
          </Button>
        ]}
      >
        <QuestionForm form={form} onFinish={saveQuestion} setNewOptions={setUpdateQuestions} />
      </Modal>
    </div>
  )
}

export default Questionary
