import { $http } from '../../util/http.utils'

class UsersService {
  getBranchUsers = () => $http.get('/branch/users')

  checkUserEmail =
    ({ email }) =>
      $http.get('/users/exist', { params: { email } })

  createUserWithEmailAndName =
    ({ email, name }) =>
      $http.post('/users', { email, name })

  setUserAdmin =
    ({ userId }) =>
      $http.put('/company/admin', { userId })

  unsetUserAdmin =
    ({ userId }) =>
      $http.delete(`/company/admin/${userId}`)

  setUserOperator =
    ({ userId }) =>
      $http.put('/branch/users/operators', { userId })

  unsetUserOperator =
    ({ userId }) =>
      $http.delete(`/branch/users/operators/${userId}`)

  setUserSupervisor =
    ({ userId }) =>
      $http.put('/branch/users/supervisors', { userId })

  unsetUserSupervisor =
    ({ userId }) =>
      $http.delete(`/branch/users/supervisors/${userId}`)

  resetPassword =
    ({ userId }) =>
      $http.put(`/users/${userId}/reset-password`)

  removeFromCompany =
    ({ userId }) =>
      $http.delete(`/company/${userId}/remove`)
}

export default new UsersService()
