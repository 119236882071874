
export const PAGINATED_RESPONSE = {
  count: 0,
  items: [],
  page: 0,
  pageSize: 0,
  sortOrder: 'ASC',
  sortProperty: '',
  total: 0,
  totalPages: 0
}
