import { extract, successState } from '../app/app.actions'
import { actionTypes } from './assignments.types'
import { PAGINATED_RESPONSE } from '../app/constants.utils'

const initialState = {
  assignments: { PAGINATED_RESPONSE },
  assignment: null,
  assignmentAnswers: null
}

const assignmentsReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.SEARCH_ASSIGNMENT):
      return {
        ...state,
        assignments: extract(data, initialState.assignments)
      }
    case successState(actionTypes.ASSIGNMENT_ANSWERS):
      return {
        ...state,
        assignmentAnswers: extract(data, initialState.assignmentAnswers)
      }
    case successState(actionTypes.SINGLE_ASSIGNMENT):
    case successState(actionTypes.EDIT_ASSIGNMENT):
    case successState(actionTypes.ADD_ASSIGNMENT):
      return {
        ...state,
        assignment: extract(data, initialState.assignment)
      }
    case successState(actionTypes.ASSIGNMENTS_CLEAR): {
      return initialState
    }
    default:
      return state || initialState
  }
}
export default assignmentsReducer
