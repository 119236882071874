import React from 'react'
import { Col, DatePicker, Form, Input, Row } from 'antd'
import { inputRequired, maxDateToday } from '../../util/forms.utils'
import moment from 'moment'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}
const dateFormat = 'DD/MM/YYYY'
const InputGroup = Input.Group
const { TextArea } = Input

const AssignmentForm = ({ assignment, form, onFinish }) => {
  const isProcessed = assignment?.status === 'ENVIADO'

  return (
    <div>
      <Form
        layout='horizontal'
        name='register'
        labelAlign='left'
        scrollToFirstError
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
      >
        <InputGroup>
          <Row>
            <Col span={12}>
              <Form.Item
                name='name'
                label='Nombre'
                rules={[inputRequired]}
                labelCol={{ sm: { span: 6 } }}
                wrapperCol={{ sm: { span: 18 } }}
                initialValue={assignment?.name}
              >
                <Input placeholder='Nombre del Encuestado' readOnly={isProcessed} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='position'
                label='Puesto'
                rules={[inputRequired]}
                labelCol={{ sm: { span: 6 } }}
                wrapperCol={{ sm: { span: 18 } }}
                initialValue={assignment?.position}
              >
                <Input placeholder='Puesto' readOnly={isProcessed} />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <InputGroup>
          <Row>
            <Col span={8}>
              <Form.Item
                name='contactPhone'
                label='Telefono'
                rules={[inputRequired]}
                labelCol={{ sm: { span: 9 } }}
                wrapperCol={{ sm: { span: 15 } }}
                initialValue={assignment?.contactPhone}
              >
                <Input placeholder='Telefono' readOnly={isProcessed} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='start'
                label='Ingreso'
                rules={[inputRequired]}
                labelCol={{ sm: { span: 8 } }}
                wrapperCol={{ sm: { span: 16 } }}
                initialValue={(assignment && assignment.startDate) ? moment(assignment.startDate, 'YYYY-MM-DDThh:mm:ss') : null}
              >
                <DatePicker
                  className='gx-w-100'
                  disabledDate={maxDateToday}
                  format={dateFormat}
                  disabled={isProcessed}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='end'
                label='Retiro'
                rules={[inputRequired]}
                labelCol={{ sm: { span: 8 } }}
                wrapperCol={{ sm: { span: 16 } }}
                initialValue={(assignment && assignment.endDate) ? moment(assignment.endDate, 'YYYY-MM-DDThh:mm:ss') : null}
              >
                <DatePicker
                  className='gx-w-100'
                  disabledDate={maxDateToday}
                  format={dateFormat}
                  readOnly={isProcessed}
                  disabled={isProcessed}
                />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>
        <Form.Item
          name='comments'
          label='Comentarios'
          labelCol={{ sm: { span: 3 } }}
          wrapperCol={{ sm: { span: 21 } }}
          initialValue={assignment?.comment}
        >
          <TextArea readOnly={isProcessed} />
        </Form.Item>
      </Form>
    </div>
  )
}

export default AssignmentForm
