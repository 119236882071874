import React from 'react'
import { Col, DatePicker, Form, Input, Row } from 'antd'
import { inputRequired, maxDateToday } from '../../util/forms.utils'
import moment from 'moment'

const dateFormat = 'DD/MM/YYYY'
const { TextArea } = Input
const InputGroup = Input.Group

const CandidateForm = ({ candidate, form, onFinish }) => {
  const isProcessed = candidate?.sentDate != null

  const getBirthDate = () => {
    let birthdate = null
    if (candidate) {
      birthdate = moment(candidate.birthdate, 'YYYY-MM-DDThh:mm:ss')
      if (isProcessed) {
        birthdate = birthdate.format('DD/MM/YYYY')
      }
    }
    return birthdate
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  }

  return (
    <div>
      <Form
        layout='horizontal'
        form={form}
        name='register'
        labelAlign='left'
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
      >
        <Form.Item
          name='name'
          label='Nombre'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
          initialValue={candidate?.name}
        >
          <Input className='disabled-input' readOnly={isProcessed} placeholder='Nombre' />
        </Form.Item>

        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='birthdate'
                label='Fecha de Nacimiento'
                rules={[inputRequired]}
                initialValue={getBirthDate()}
              >
                {isProcessed
                  ? <Input className='disabled-input' readOnly={isProcessed} placeholder='1/1/2021' />
                  : (
                    <DatePicker
                      readOnly={isProcessed} className='gx-w-100'
                      disabledDate={maxDateToday}
                      format={dateFormat}
                    />
                  )}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='birthplace'
                label='Lugar de nacimiento'
                rules={[inputRequired]}
                initialValue={candidate?.birthplace}
              >
                <Input readOnly={isProcessed} placeholder='Lugar de nacimiento' />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='address'
                label='Domicilio'
                rules={[inputRequired]}
                initialValue={candidate?.address}
              >
                <Input readOnly={isProcessed} placeholder='Domicilio' />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='city'
                label='Ciudad'
                rules={[inputRequired]}
                initialValue={candidate?.city}
              >
                <Input readOnly={isProcessed} placeholder='Ciudad' />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='phone'
                label='Tel. Personal'
                rules={[inputRequired]}
                initialValue={candidate?.phone}
              >
                <Input readOnly={isProcessed} placeholder='Telefono Personal' />
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item
                name='personalPhone'
                label='Tel. Recados'
                initialValue={candidate?.personalPhone}
              >
                <Input readOnly={isProcessed} placeholder='Telefono Recados' />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='imss'
                label='Numero IMSS'
                rules={[inputRequired]}
                initialValue={candidate?.imss}
              >
                <Input readOnly={isProcessed} placeholder='Numero IMSS' />
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item
                name='curp'
                label='CURP'
                rules={[inputRequired]}
                initialValue={candidate?.curp}
              >
                <Input readOnly={isProcessed} placeholder='Numero CURP' />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <Form.Item
          name='family'
          label='Familiares'
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
          initialValue={candidate?.family}
        >
          <TextArea readOnly={isProcessed} />
        </Form.Item>

      </Form>
    </div>
  )
}

export default CandidateForm
