import React from 'react'
import { Col, Form, Input, Row, Switch } from 'antd'
import { inputRequired } from '../../util/forms.utils'
import Text from "antd/lib/typography/Text";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const UserForm = ({ form, showRoles, onChange, formError, onFinish }) => {
  return (
    <Form form={form} onValuesChange={onChange} onFinish={onFinish} scrollToFirstError {...formItemLayout}>
      <Form.Item
        label='Correo'
        placeholder='Correo'
        name='email'
        rules={[inputRequired]}
        labelCol={{ sm: { span: 4 } }}
        wrapperCol={{ sm: { span: 20 } }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Nombre'
        name='name'
        placeholder='Nombre'
        rules={[inputRequired]}
        labelCol={{ sm: { span: 4 } }}
        wrapperCol={{ sm: { span: 20 } }}
      >
        <Input />
      </Form.Item>

      {
        showRoles &&
          <Input.Group>
            <Row>
              <Col span={4} />
              <Col span={10}>
                <Form.Item label='Operador' name='isOperator' valuePropName='checked'>
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label='Supervisor' name='isSupervisor' valuePropName='checked'>
                  <Switch />
                </Form.Item>
              </Col>
              {formError && <Text type='danger' className='gx-w-100 gx-p-2 gx-text-center'>{formError}</Text>}
            </Row>
          </Input.Group>
      }
    </Form>
  )
}

export default UserForm
