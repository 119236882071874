import { combineReducers } from 'redux'
import loading from './app/loading/loading.reducer'
import error from './app/error/error.reducer'
import { connectRouter } from 'connected-react-router'
import settings from './settings/settings.reducer'
import navigation from './navigation/navigation.reducer'
import auth from './auth/auth.reducer.js'
import candidates from './candidates/candidates.reducer'
import users from './users/users.reducer'
import branches from './branches/branch.reducer'
import statistics from './statistics/statistics.reducer'
import invoices from './invoice/invoice.reducer'
import companies from './company/company.reducer'
import surveys from './surveys/surveys.reducer'
import assignments from './assignments/assignments.reducer'

export const rootReducer = history => combineReducers({
  router: connectRouter(history),
  loading,
  error,
  branches,
  settings,
  navigation,
  companies,
  auth,
  invoices,
  candidates,
  users,
  surveys,
  assignments,
  statistics
})
