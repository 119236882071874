import React, {useState} from 'react'
import {Avatar, Button, Modal, Tag, Timeline, Tooltip} from "antd";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import moment from "moment";
import {Link} from "react-router-dom";
import {createFromIconfontCN, UserSwitchOutlined} from "@ant-design/icons";

const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/font_2419499_5kk5y6c6hnb.js'
  ]
})

const ProcessAssignmentCell = ({ assignment, handleModal }) => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)
  const status = assignment.investigationDate ? 'En investigación' : 'Enviado'
  const color = assignment.investigationDate ? 'green' : 'orange'
  const icon = assignment.investigationDate ? 'icon-incognito' : 'icon-plane'

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Tooltip title={status}>
            <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size='large'>
              <IconFont type={icon} />
            </Avatar>
          </Tooltip>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row align='middle'>
            <Col span={3}>
              <Tooltip title='Folio'>
                <span className='gx-text-truncate'>#{assignment.id} </span>
              </Tooltip>
            </Col>
            <Col span={3}>
              <Tooltip title='Fecha de envío'>
                <span className='gx-text-truncate'>{moment(assignment.sentDate).format('DD/MM/yyyy')} </span>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Nombre del encuestado'>
                <span className='gx-text-uppercase'>{assignment.name}</span>
              </Tooltip>
            </Col>
            <Col span={5}>
              <Tooltip title='Usuario'>
                <span className='gx-text-muted' style={{ fontSize: 12 }}>{assignment.createdByName}</span>
              </Tooltip>
            </Col>
            <Col span={5}>
              <Tag color={color} style={{ width: '120px', textAlign: 'center', marginTop: 5 }}>{status}</Tag>
            </Col>
          </Row>
        </div>
        <div className='gx-module-contact-right'>
          <Row>
            <Col span={8}>
              <Tooltip title='Ver detalles'>
                <Link to={`/surveys/assignments/${assignment.id}`}>
                  <i className='gx-icon-btn icon icon-eye' />
                </Link>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title='Historial'>
                <i onClick={toggleModal} className='gx-icon-btn icon icon-timeline-new' />
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title='Reasignar'>
                <span onClick={() => handleModal(assignment)}>
                  <UserSwitchOutlined />
                </span>
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        title='Historial'
        visible={showModal}
        onCancel={toggleModal}
        footer={[
          <Button
            key='ok'
            type='primary'
            onClick={toggleModal}
          > Ok
          </Button>
        ]}
      >
        <div>
          <Timeline>
            <Timeline.Item color='green'>
              Encuesta creada: {moment(assignment.createdAt).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color='green'>
              Encuesta solicitada: {moment(assignment.sentDate).format('DD/MM/yyyy')}
            </Timeline.Item>
            <Timeline.Item color={assignment.investigationDate ? 'green' : 'orange'}>
              {
                assignment.investigationDate
                  ? `Inicio de Proceso: ${moment(assignment.investigationDate).format('DD/MM/yyyy')}`
                  : 'Inicio de Proceso: Pendiente'
              }
            </Timeline.Item>
            <Timeline.Item color='grey'>Resultados: Pendiente </Timeline.Item>
          </Timeline>
        </div>
      </Modal>
    </div>
  )
}

export default ProcessAssignmentCell
