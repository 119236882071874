export const actionTypes = {
  ADD_ASSIGNMENT: 'assignment/add',
  REASSIGN_ASSIGNMENT: 'assignment/reassign',
  DELETE_ASSIGNMENT: 'assignment/delete',
  DELETE_ASSIGNMENTS: 'assignments/delete',
  EDIT_ASSIGNMENT: 'assignment/edit',
  PROCESS_ASSIGNMENT: 'assignment/process',
  PROCESS_ASSIGNMENTS: 'assignments/process',
  SEARCH_ASSIGNMENT: 'assignments/search',
  SINGLE_ASSIGNMENT: 'assignment/get/single',
  ASSIGNMENT_ANSWERS: 'assignment/get/answers',
  ASSIGNMENT_REPORT: 'survey/report',
  ASSIGNMENTS_CLEAR: 'survey/clear'
}
