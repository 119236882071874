import { extract, successState } from '../app/app.actions'
import { actionTypes } from './surveys.types'

const initialState = {
  survey: {},
  question: {
    id: 0,
    limit: 0,
    options: [],
    text: '',
    type: ''
  }
}

const surveysReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.GET_SURVEY):
      return {
        ...state,
        survey: extract(data, initialState.survey)
      }
    case successState(actionTypes.GET_QUESTION):
      return {
        ...state,
        question: extract(data, initialState.question)
      }
    case successState(actionTypes.CLEAR_SURVEYS):
      return initialState
    default:
      return state || initialState
  }
}
export default surveysReducer
