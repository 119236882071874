export const actionTypes = {
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  UPDATE_USER: 'auth/user/update',
  GET_ROLES: 'auth/user/roles',
  SET_COMPANY: 'auth/company/set',
  SET_BRANCH: 'auth/branch/set',
  HIDE_LOGOUT: 'auth/hide/logout',
  SHOW_LOGOUT: 'auth/show/logout'
}
