import { actionTypes } from './auth.types'
import { extract, successState } from '../app/app.actions'

const initialState = {
  companies: [],
  accessToken: null,
  user: null,

  currentCompany: null,
  currentBranch: null,
  logout: false
}

const authorization = (state = initialState, { type, data }) => {
  switch (type) {
    case successState(actionTypes.LOGIN):
      return {
        ...state,
        companies: extract(data.companies, []),
        accessToken: extract(data.token),
        user: extract(data.user)
      }
    case successState(actionTypes.SET_COMPANY):
      return {
        ...state,
        currentCompany: data
      }
    case successState(actionTypes.SET_BRANCH):
      return {
        ...state,
        currentBranch: data
      }
    case successState(actionTypes.UPDATE_USER):
      return {
        ...state,
        user: data
      }
    case successState(actionTypes.GET_ROLES):
      return {
        ...state,
        companies: data
      }
    case successState(actionTypes.HIDE_LOGOUT):
      return {
        ...state,
        logout: false
      }
    case successState(actionTypes.SHOW_LOGOUT):
      return {
        ...state,
        logout: true
      }
    case successState(actionTypes.LOGOUT):
      return initialState
    default:
      return state || initialState
  }
}

export default authorization
