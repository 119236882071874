import React, { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, Form, Modal, Pagination, Row, Tooltip } from 'antd'
import CandidateCell from '../../components/Candidate/CandidateCell'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import CandidateForm from '../../components/Candidate/CandidateForm'
import '../../styles/pages/candidates.less'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import {
  addCandidate,
  deleteCandidates,
  processCandidates,
  searchCandidates
} from '../../store/candidates/candidates.actions'
import { selectCandidates } from '../../store/candidates/candidates.selector'
import { NOOP } from '../../util/functions.utils'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/candidates/candidates.types'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { ExclamationCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { clearActionResult } from '../../store/app/app.actions'
import { UNEXPECTED_OOPS } from '../../util/messages.utils'
import { selectCurrentBranch, selectCurrentCompany, selectIsOperator } from '../../store/auth/auth.selectors'

const sortProperties = [
  { label: 'Folio', property: 'id' },
  { label: 'Nombre', property: 'name' },
  { label: 'Fecha', property: 'createdAt' },
  { label: 'Empleos', property: 'jobsCount' }
]

const { confirm, success, error } = Modal

const DraftCandidates = () => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [selected, setSelected] = useState([])
  const [form] = Form.useForm()
  const [searchForm] = Form.useForm()
  const isOperator = useSelector(selectIsOperator)
  const dispatch = useDispatch()
  const candidates = useSelector(selectCandidates)
  const data = candidates?.items || []
  const [candidatesLoading] = useIsLoading([actionTypes.SEARCH_CANDIDATES])
  const [deleteIsLoading, deleteFinished] = useIsLoading([actionTypes.DELETE_CANDIDATE])
  const [saveIsLoading, saveFinished] = useIsLoading([actionTypes.ADD_CANDIDATE])
  const [processCandidateIsLoading, processCandidateFinished] = useIsLoading([actionTypes.PROCESS_CANDIDATE])
  const [processCandidatesIsLoading, processCandidatesFinished] = useIsLoading([actionTypes.PROCESS_CANDIDATES])
  const [processCandidateError, processCandidateHasError] = useHasErrors([actionTypes.PROCESS_CANDIDATE])
  const [processCandidatesError, processCandidatesHasError] = useHasErrors([actionTypes.PROCESS_CANDIDATES])
  const [deleteError, deleteHasError] = useHasErrors([actionTypes.DELETE_CANDIDATE])
  const [deleteCandidatesError, deleteCandidatesHasError] = useHasErrors([actionTypes.DELETE_CANDIDATES])
  const [deleteCandidatesIsLoading, deleteCandidatesFinished] = useIsLoading([actionTypes.DELETE_CANDIDATES])

  const refresh = useCallback(({ search, last, from, to, sortOrder, sortProperty } = {}) => {
    setSelected([])
    dispatch(searchCandidates({
      status: 'INGRESO',
      search,
      from: from?.unix(),
      to: to?.unix(),
      last,
      ...pagination,
      sortOrder,
      sortProperty
    }))
  }, [dispatch, pagination])

  const currentCompany = useSelector(selectCurrentCompany)
  const currentBranch = useSelector(selectCurrentBranch)
  useEffect(refresh, [currentCompany, currentBranch])

  useEffect(() => {
    if (saveFinished) {
      refresh()
      setShowModal(false)
    }
  }, [refresh, saveFinished])

  useEffect(() => {
    if (processCandidateFinished) {
      if (processCandidateHasError) {
        error({
          title: '¡Uh-oh!',
          content: processCandidateError.message || UNEXPECTED_OOPS
        })
      } else {
        setShowModal(false)
        success({
          title: '¡Exito!',
          content: 'Candidato procesado exitosamente'
        })
        refresh()
      }
    }
  }, [processCandidateFinished, processCandidateHasError, processCandidateError.message, refresh])

  useEffect(() => {
    if (processCandidatesFinished) {
      if (processCandidatesHasError) {
        error({
          title: '¡Uh-oh!',
          content: processCandidatesError.message || UNEXPECTED_OOPS
        })
      } else {
        setShowModal(false)
        success({
          title: '¡Exito!',
          content: 'Candidatos procesados exitosamente'
        })
        refresh()
      }
    }
  }, [processCandidatesFinished, processCandidatesHasError, processCandidatesError.message, refresh])

  useEffect(() => {
    if (deleteCandidatesFinished) {
      if (deleteCandidatesHasError) {
        error({
          title: '¡Uh-oh!',
          content: deleteCandidatesError.message || UNEXPECTED_OOPS
        })
      } else {
        setShowModal(false)
        success({
          title: '¡Exito!',
          content: 'Candidatos eliminados exitosamente'
        })
        refresh()
      }
    }
  }, [deleteCandidatesFinished, deleteCandidatesHasError, deleteCandidatesError.message, refresh])

  useEffect(() => {
    if (deleteFinished) {
      if (deleteHasError) {
        error({
          title: '¡Uh-oh!',
          content: deleteError.message
        })
      } else {
        success({
          title: '¡Exito!',
          content: 'Candidato eliminado exitosamente'
        })
        refresh()
      }
    }
  }, [deleteError.message, deleteFinished, deleteHasError, refresh])

  const init = () => {
    dispatch(updateTopBarTitle('Candidatos'))
    dispatch(searchCandidates({ status: 'INGRESO' }))
    return () => dispatch(clearActionResult(actionTypes.SEARCH_CANDIDATES))
  }
  useEffect(init, [])

  const onPageChange = (page, pageSize) => {
    setPagination({ page, pageSize })
    const search = searchForm.getFieldValue('search')
    const from = searchForm.getFieldValue('from')
    const to = searchForm.getFieldValue('to')
    const last = searchForm.getFieldValue('last')

    dispatch(searchCandidates({
      status: 'INGRESO',
      search,
      last,
      from: from?.unix(),
      to: to?.unix(),
      page,
      pageSize,
      sortProperty: candidates.sortProperty,
      sortOrder: candidates.sortOrder,
      byUser: null
    }))
  }

  const saveCandidate = ({ name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family }) => {
    dispatch(addCandidate({
      name,
      birthdate: birthdate.format('DD/MM/YYYY'),
      birthplace,
      address,
      city,
      phone,
      personalPhone,
      imss,
      curp,
      family
    }))
  }

  const selectCandidate = (id) => {
    const index = selected.indexOf(id)
    if (index !== -1) {
      selected.splice(index, 1)
      setSelected([...selected])
    } else {
      setSelected([...selected, id])
    }
  }

  const isSelected = id => selected.indexOf(id) !== -1
  const toggleSelectAll = () => {
    if (selected.length < candidates.items.length) {
      setSelected(candidates.items.map(item => item.id))
    } else {
      setSelected([])
    }
  }

  const handleProcessCandidates = () => {
    confirm({
      onType: 'danger',
      onText: 'Procesar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <QuestionCircleOutlined />,
      content: `¿Está seguro que desea enviar ${selected.length} candidatos a evaluación? Esta operación no se puede revertir`,
      onOk: () => dispatch(processCandidates(selected))
    })
  }

  const handleDeleteCandidates = () => {
    confirm({
      onType: 'danger',
      onText: 'Procesar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <ExclamationCircleOutlined />,
      content: `¿Está seguro que desea eliminar ${selected.length} candidatos? Esta operación no se puede revertir`,
      onOk: () => dispatch(deleteCandidates(selected))
    })
  }

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={candidatesLoading || deleteIsLoading || processCandidateIsLoading || processCandidatesIsLoading || deleteCandidatesIsLoading} />
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <div style={{ display: 'flex' }}>
                <Checkbox
                  color='primary' className='gx-icon-btn'
                  onClick={toggleSelectAll}
                  checked={selected?.length === candidates?.items?.length} i
                  indeterminate={selected.length > 0 && selected.length < candidates.items.length}
                />
                {
                  selected.length > 0 &&
                    <Row align='center' style={{ marginTop: 2.5 }}>
                      <div style={{ display: 'flex', flexGrow: 1 }}>
                        <Button type='text' icon={<i className='icon icon-menu-right gx-mr-2' />} onClick={handleProcessCandidates}>
                          Procesar Seleccionados
                        </Button>
                        <Button type='text' danger icon={<i className='icon icon-trash gx-mr-2' />} onClick={handleDeleteCandidates}>
                          Eliminar Seleccionados
                        </Button>
                      </div>
                    </Row>
                }
              </div>
              <CustomSearch
                form={searchForm}
                onChange={refresh}
                sortProperties={sortProperties}
                style={{ display: selected.length === 0 ? 'flex' : 'none' }}
              />
              {
                isOperator &&
                  <Row justify='end' style={{ flexGrow: 1, paddingRight: 30 }}>
                    <Tooltip title='Agregar Candidato' placement='topLeft'>
                      <Button
                        size='large'
                        className='green-button'
                        shape='circle' type='primary'
                        icon={<PlusOutlined />}
                        aria-label='add' onClick={toggleModal}
                      />
                    </Tooltip>
                  </Row>
              }

            </div>
            <CustomScrollbars className='candidate-scroll'>
              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      No se encontraron candidatos con los filtros específicados
                    </div>
                  )
                  : data.map((candidate) =>
                    <CandidateCell
                      candidate={candidate} handleDelete={NOOP}
                      key={`${candidate.id}`} onSelect={selectCandidate}
                      isSelected={isSelected}
                    />)
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                disabled={selected.length > 0}
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={candidates?.total || 0}
                current={candidates?.page || 0}
                pageSize={candidates?.pageSize || 0}
                hideOnSinglePage={candidates.total === 0}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
      <Modal
        centered
        width={1024}
        title='Agregar Candidato'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Registrar'
            type='primary'
            htmlType='submit'
            onClick={() => form.submit()}
            loading={saveIsLoading}
          >
            Registrar
          </Button>
        ]}
      >
        <CandidateForm form={form} onFinish={saveCandidate} />
      </Modal>
    </div>
  )
}
export default DraftCandidates
