import { extract, successState } from '../app/app.actions'
import { actionTypes } from './company.types'

const initialState = {
  current: {}
}

const CompanyReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.UPDATE_COMPANY):
    case successState(actionTypes.GET_COMPANY):
      return {
        ...state,
        current: extract(data, initialState.current)
      }
    case successState(actionTypes.CLEAR_COMPANY):
      return initialState
    default:
      return state || initialState
  }
}

export default CompanyReducer
