import { useRef, useEffect } from 'react'

export const usePrevious = (value, initialValue = null) => {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => { savedCallback.current = callback }, [callback])

  useEffect(() => {
    const tick = () => { savedCallback.current() }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length) {
    // eslint-disable-next-line no-console
    console.log('[use-effect-debugger] ', JSON.stringify(changedDeps, null, 1))
  }
  useEffect(effectHook, dependencies)
}
