import React, { useEffect, useState } from 'react'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import { Avatar, Badge, Button, Form, Modal, Row, Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import CandidateForm from '../../components/Candidate/CandidateForm'
import CandidateJobsForm from '../../components/Candidate/CandidateJobsForm'
import CandidateJobCell from '../../components/Candidate/CandidateJobCell'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import {
  addCandidateJob,
  deleteCandidateJob,
  editCandidate,
  editCandidateJob,
  getCandidateJobs,
  getSingleCandidate
} from '../../store/candidates/candidates.actions'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/candidates/candidates.types'
import { selectCurrentCandidate, selectCurrentJobs } from '../../store/candidates/candidates.selector'
import { clearActionResult } from '../../store/app/app.actions'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { GENERIC_MESSAGE_ERROR } from '../../util/messages.utils'

const TabPane = Tabs.TabPane
const { confirm, success, error } = Modal

const SingleCandidate = () => {
  const { candidateId } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  const [jobForm] = Form.useForm()
  const [loading] = useIsLoading([actionTypes.SINGLE_CANDIDATE])
  const [editCandidateError, editCandidateHasError] = useHasErrors([actionTypes.EDIT_CANDIDATE])
  const [editLoading, editFinished] = useIsLoading([actionTypes.EDIT_CANDIDATE])
  const [jobsLoading, jobsFinished] = useIsLoading([actionTypes.ADD_CANDIDATE_JOBS])
  const [editJobLoading, editJobFinished] = useIsLoading([actionTypes.EDIT_CANDIDATE_JOB, actionTypes.ADD_CANDIDATE_JOBS])
  const [editJobError, editJobHasError] = useHasErrors([actionTypes.EDIT_CANDIDATE_JOB])
  const [jobsDelLoading, jobsDelFinished] = useIsLoading([actionTypes.DELETE_CANDIDATE_JOB])
  const [deleteError, deleteHasError] = useHasErrors([actionTypes.DELETE_CANDIDATE_JOB])
  const candidate = useSelector(selectCurrentCandidate)
  const jobs = useSelector(selectCurrentJobs)
  const [currentJob, setCurrentJob] = useState(null)

  const toggleModal = () => {
    if (showModal) {
      setCurrentJob(null)
    }
    setShowModal(!showModal)
  }

  const dispatch = useDispatch()

  const init = () => {
    dispatch(updateTopBarTitle(candidate?.name || ' '))
    dispatch(getSingleCandidate(candidateId))
    dispatch(getCandidateJobs(candidateId))
    return () => dispatch(clearActionResult(actionTypes.SINGLE_CANDIDATE))
  }
  useEffect(init, [])

  useEffect(() => {
    dispatch(updateTopBarTitle(candidate?.name || ' '))
  }, [candidate, dispatch])
  const isProcessed = candidate?.sentDate != null

  const saveCandidate = ({ name, birthdate, birthplace, address, city, phone, personalPhone, imss, curp, family }) => {
    dispatch(editCandidate({
      id: candidateId,
      name,
      birthdate: birthdate.format('DD/MM/YYYY'),
      birthplace,
      address,
      city,
      phone,
      personalPhone,
      imss,
      curp,
      family
    }))
  }

  useEffect(() => {
    if (editFinished) {
      if (editCandidateHasError) {
        error({
          title: '¡Uh-oh!',
          content: editCandidateError.message || GENERIC_MESSAGE_ERROR
        })
      } else {
        success({
          title: '¡Exito!',
          content: 'Candidato editado exitosamente'
        })
      }
    }
  }, [candidateId, dispatch, editCandidateError, editCandidateHasError, editFinished])

  useEffect(() => {
    if (editJobFinished) {
      if (editJobHasError) {
        error({
          title: '¡Uh-oh!',
          content: editJobError.message || GENERIC_MESSAGE_ERROR
        })
      } else {
        setShowModal(false)
        setCurrentJob(null)
        success({
          title: 'Exito',
          content: 'Empleo guardado exitosamente'
        })
      }
    }
  }, [editJobError.message, editJobHasError, editJobFinished])

  const saveJob = ({ company, lada, phone, position, boss, start, end, reason }) => {
    if (currentJob) {
      dispatch(editCandidateJob({
        jobId: currentJob.id,
        candidateId,
        company,
        lada,
        phone,
        position,
        boss,
        start: start.format('DD/MM/YYYY'),
        end: end ? end.format('DD/MM/YYYY') : null,
        reason
      }))
    } else {
      dispatch(addCandidateJob({
        candidateId,
        company,
        lada,
        phone,
        position,
        boss,
        start: start.format('DD/MM/YYYY'),
        end: end ? end.format('DD/MM/YYYY') : null,
        reason
      }))
    }
  }

  const handleDeleteJob = (job) => {
    confirm({
      okType: 'danger',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <ExclamationCircleOutlined />,
      content: `¿Está seguro de eliminar ${job.position} en ${job.company}?, esta operación no se puede revertir.`,
      onOk: () => dispatch(deleteCandidateJob({ candidateId: job.candidateId, jobId: job.id }))
    })
  }

  const openEditModal = job => {
    setCurrentJob(job)
    setShowModal(true)
  }

  useEffect(() => {
    if (jobsDelFinished) {
      if (deleteHasError) {
        error({
          title: '¡Uh-oh!',
          content: deleteError.message
        })
      } else {
        success({
          title: '¡Exito!',
          content: 'Empleo eliminado exitosamente'
        })
        dispatch(getCandidateJobs(candidateId))
      }
    }
  }, [jobsFinished, deleteHasError, deleteError, jobsDelFinished, dispatch, candidateId])

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={loading || editLoading || jobsDelLoading} />
            <CustomScrollbars className='candidate-tabs-scroll'>
              <div style={{ padding: '28px' }}>
                <Tabs type='card'>
                  <TabPane tab='Informacion Basica' key='1'>
                    {
                      candidate != null &&
                        <CandidateForm candidate={candidate} form={form} onFinish={saveCandidate} />
                    }
                    {
                      !isProcessed &&
                        <Row justify='end' className='gx-pr-3'>
                          <Button type='primary' onClick={() => form.submit()} loading={editLoading}>
                            Guardar Cambios
                          </Button>
                        </Row>
                    }
                  </TabPane>
                  <TabPane
                    tab={
                      <div>
                        <span style={{ marginRight: '25px' }}>Empleos</span>
                        <Badge style={{ marginLeft: '5px', marginRight: 5 }} count={candidate?.jobsCount}>
                          <div className='head-example' />
                        </Badge>
                      </div>
                    } key='2'
                  >
                    {
                      !loading && candidate != null &&
                      (
                        <CustomScrollbars className='candidate-jobs-scroll'>
                          {
                            jobs.length === 0 && !isProcessed
                              ? (
                                <div
                                  className='gx-h-100 gx-w-100 gx-d-flex gx-align-items-center gx-justify-content-center'
                                  style={{ cursor: 'pointer' }}
                                >
                                  <Row align='center' justify='center' onClick={toggleModal} style={{ cursor: 'pointer' }}>
                                    <Button
                                      type='primary'
                                      className='green-button'
                                      size='large'
                                      shape='circle' icon={<PlusOutlined />}
                                    />
                                    <span style={{ marginTop: 10 }}>Agregar Empleo</span>
                                  </Row>
                                </div>
                              )
                              : (
                                <div>
                                  {(jobs || []).map((job, index) =>
                                    <CandidateJobCell
                                      isProcesed={isProcessed} job={job}
                                      key={`job-${job.id}`} index={index}
                                      handleEdit={openEditModal}
                                      handleDelete={handleDeleteJob}
                                    />)}
                                  <div style={{ height: '1em' }} />
                                  {
                                    isProcessed
                                      ? (
                                        <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                                          El candidato ya ha sido enviado a evaluación, no se pueden agregar más empleos.
                                        </div>
                                      )
                                      : (
                                        <div className='gx-contact-item' onClick={toggleModal}>
                                          <div className='gx-module-list-icon'>
                                            <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
                                              <Avatar
                                                style={{
                                                  backgroundColor: 'green',
                                                  verticalAlign: 'middle',
                                                  fontSize: 'xx-large'
                                                }} size='large'
                                              >
                                                +
                                              </Avatar>
                                            </div>
                                          </div>
                                          <div className='gx-module-list-info gx-contact-list-info'>
                                            <div className='gx-module-contact-content'>
                                              <p className='gx-mb-1'>
                                                <span className='gx-text-truncate gx-contact-name'> Agregar Empleo </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                  }
                                </div>
                              )
                          }
                        </CustomScrollbars>
                      )
                    }
                  </TabPane>
                </Tabs>
              </div>
            </CustomScrollbars>
          </div>
        </div>
      </div>
      <Modal
        centered
        width={1024}
        title={currentJob ? 'Editar Empleo' : 'Agregar Empleo'}
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        destroyOnClose
        footer={[
          <Button key='Cancelar' onClick={toggleModal}> Cancelar </Button>,
          <Button
            key='Registrar'
            type='primary'
            onClick={() => jobForm.submit()} loading={jobsLoading || editJobLoading}
          >
            Guardar
          </Button>
        ]}
      >
        <CandidateJobsForm job={currentJob} form={jobForm} onFinish={saveJob} />
      </Modal>
    </div>
  )
}

export default SingleCandidate
