import React, { useEffect } from 'react'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import { Button, Form, Modal, Row, Tabs } from 'antd'
import BranchForm from '../../components/Branch/BranchForm'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleBranch, updateBranch } from '../../store/branches/branch.actions'
import { useParams } from 'react-router-dom'
import { selectBranch } from '../../store/branches/branch.selectors'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/branches/branch.types'
import { useHasErrors } from '../../store/app/error/error.hooks'
import {GENERIC_MESSAGE_ERROR} from "../../util/messages.utils";

const TabPane = Tabs.TabPane
const { success, error } = Modal

const SingleBranch = () => {
  const dispatch = useDispatch()
  const [editLoading, editFinished] = useIsLoading([actionTypes.UPDATE_BRANCH])
  const [editError, editHasError] = useHasErrors([actionTypes.UPDATE_BRANCH])
  const { branchId } = useParams()

  const branch = useSelector(selectBranch)
  const [form] = Form.useForm()

  useEffect(() => {
    if (branch != null) {
      form.setFieldsValue(branch)
    } else {
      form.resetFields()
    }
  }, [form, branch])

  const onFinish = ({ address, contactName, email, name, phone }) => {
    dispatch(updateBranch({ id: branchId, address, contactName, email, name, phone }))
  }

  useEffect(() => {
    if (editFinished) {
      if (editHasError) {
        error({
          title: '¡Uh-oh!',
          content: editError.message || GENERIC_MESSAGE_ERROR
        })
      } else {
        success({
          title: 'Éxito',
          content: 'Sucursal editada'
        })
      }
    }
  }, [editError.message, editFinished, editHasError])

  const init = () => {
    dispatch(getSingleBranch({ id: branchId }))
  }

  useEffect(init, [])

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <CustomScrollbars className='candidate-tabs-scroll'>
              <div style={{ padding: '28px' }}>
                <Tabs type='card'>
                  <TabPane tab='Informacion Basica' key='1'>
                    <BranchForm branch={branch} onFinish={onFinish} form={form} />
                    <Row justify='end' className='gx-pr-3'>
                      <Button type='primary' onClick={() => form.submit()} loading={editLoading}>
                        Guardar Cambios
                      </Button>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </CustomScrollbars>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleBranch
