import { extract, successState } from '../app/app.actions'
import { actionTypes } from './candidates.types'

const initialState = {
  candidates: { items: [], page: 1, pageSize: 10 },
  current: null,
  currentJobs: []
}

const candidatesReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.SEARCH_CANDIDATES):
      return {
        ...state,
        candidates: extract(data, initialState.candidates)
      }
    case successState(actionTypes.SINGLE_CANDIDATE):
    case successState(actionTypes.EDIT_CANDIDATE):
    case successState(actionTypes.ADD_CANDIDATE):
      return {
        ...state,
        current: data
      }
    case successState(actionTypes.CANDIDATE_JOBS):
      return {
        ...state,
        currentJobs: data
      }
    case successState(actionTypes.CLEAR_CANDIDATES):
      return initialState
    default:
      return state || initialState
  }
}

export default candidatesReducer
