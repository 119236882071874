import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Form, Input, Row } from 'antd'
import { inputRequired, maxDateToday } from '../../util/forms.utils'
import moment from 'moment'

const dateFormat = 'DD/MM/YYYY'
const InputGroup = Input.Group

const CandidateJobsForm = ({ job, onFinish, form }) => {
  useEffect(() => {
    if (job != null) {
      form.setFieldsValue({
        start: moment(job.startDate),
        end: job.endDate && moment(job.endDate),
        ...job
      })
    } else {
      form.resetFields()
    }
  }, [form, job])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  }

  const [reasonRequired, setReasonRequired] = useState([])
  const onChange = () => {
    const end = form.getFieldValue('end')
    if (end != null) {
      setReasonRequired([inputRequired])
    } else {
      setReasonRequired([])
    }
  }

  return (
    <div>
      <Form
        layout='horizontal'
        form={form}
        name='register'
        scrollToFirstError
        onFinish={onFinish}
        {...formItemLayout}
        onValuesChange={onChange}
      >
        <Form.Item
          name='company'
          label='Empresa'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input placeholder='Nombre de la empresa' />
        </Form.Item>

        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='lada'
                label='Lada'
              >
                <Input placeholder='Lada' />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='phone'
                label='Telefono'
              >
                <Input placeholder='Telefono' />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <Form.Item
          name='position'
          label='Puesto'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input placeholder='Puesto' />
        </Form.Item>

        <Form.Item
          name='boss'
          label='Jefe'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input placeholder='Jefe directo' />
        </Form.Item>

        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='start'
                label='Ingreso'
                rules={[inputRequired]}
              >
                <DatePicker
                  className='gx-w-100'
                  disabledDate={maxDateToday}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='end'
                label='Baja'
              >
                <DatePicker
                  className='gx-w-100'
                  disabledDate={maxDateToday}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <Form.Item
          name='reason'
          label='Comentarios'
          rules={reasonRequired}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input placeholder='Motivo de baja o comentarios sobre el empleo' />
        </Form.Item>

      </Form>
    </div>
  )
}

export default CandidateJobsForm
