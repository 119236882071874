import React from 'react'
import { Avatar, Col, Row, Tag, Tooltip } from 'antd'
import { NOOP } from '../../util/functions.utils'
import { useSelector } from 'react-redux'
import { selectIsAdmin } from '../../store/auth/auth.selectors'
import '../../styles/pages/users.less'

const UserCell = ({ user, handleResetPassword, handleSupervisor, handleDelete, handleRemoveFomCompany, handleOperator, handleAdmin, current = false }) => {
  const color = current ? 'green' : 'rgb(110, 142, 183)'
  const isAdmin = useSelector(selectIsAdmin)
  const style = { width: '75px', textAlign: 'center', cursor: current ? 'not-allowed' : 'pointer' }

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size='large'>
            {user.name[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row>
            <Col span={9}>
              <span className='gx-text-truncate'>{user.name} {current ? '(usted)' : ''} </span>
            </Col>
            <Col span={7}>
              <span className='gx-text-truncate' style={{ fontSize: 12 }}>{user.email}</span>
            </Col>
            <Col span={8} className='role-list'>
              {
                isAdmin &&
                  <Tag onClick={current ? NOOP : () => handleAdmin(user, user.admin)} color={user.admin ? 'blue' : ''} style={style}>
                    Admin
                  </Tag>
              }
              <Tag onClick={current ? NOOP : () => handleSupervisor(user, user.supervisor)} color={user.supervisor ? 'blue' : ''} style={style}>
                Supervisor
              </Tag>
              <Tag onClick={current ? NOOP : () => handleOperator(user, user.operator)} color={user.operator ? 'blue' : ''} style={style}>
                Operador
              </Tag>
            </Col>
          </Row>
        </div>
      </div>
      <div className='gx-module-contact-right'>
        <Row>
          <Col>
            {
              !current &&
                <Tooltip title='Eliminar de la sucursal' onClick={() => handleDelete(user)} placement='topLeft'>
                  <i className='gx-icon-btn icon icon-trash' style={{ color: 'orangered' }} />
                </Tooltip>
            }
          </Col>
          <Col>
            {
              !current &&
                <Tooltip title='Recuperar Contraseña' onClick={() => handleResetPassword(user.id)} placement='topLeft'>
                  <i className='gx-icon-btn icon icon-frequent' />
                </Tooltip>
            }
          </Col>

          <Col>
            {
              isAdmin && !current
                ? (
                  <Tooltip title='Eliminar de toda la empresa' onClick={() => handleRemoveFomCompany(user)} placement='topLeft'>
                    <i className='gx-icon-btn icon icon-close' style={{ fontSize: 24, color: 'red' }} />
                  </Tooltip>
                )
                : <div style={{ width: 119 }} />
            }
          </Col>
        </Row>
      </div>

    </div>
  )
}

export default UserCell
