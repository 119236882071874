import React, { useState } from 'react'
import { Layout, Row, Typography } from 'antd'
import { selectTopBarTitle } from '../../store/settings/settings.selectors'
import { useSelector } from 'react-redux'
import { selectCurrentBranch, selectCurrentCompany } from '../../store/auth/auth.selectors'
import CompanySelector from '../../pages/Dashboard/CompanySelector'

const { Header } = Layout
const { Title } = Typography

const Topbar = () => {
  const [showCompanySelector, setShowCompanySelector] = useState(false)
  const toggleCompanySelector = () => setShowCompanySelector(!showCompanySelector)

  const currentCompany = useSelector(selectCurrentCompany)
  const currentBranch = useSelector(selectCurrentBranch)

  const company = currentCompany?.name || 'No hay empresa seleccionada'
  const branch = currentBranch?.name || 'No hay sucursal seleccionada'

  const title = useSelector(selectTopBarTitle)

  return (
    <Header>
      <div>
        <Row>
          <Title level={2} style={{ marginBottom: 0, textTransform: 'uppercase' }}>{title}</Title>
        </Row>
        <Row>
          <h6>
            {company} > {branch}
            <span
              onClick={toggleCompanySelector}
              style={{ marginLeft: 20, textDecoration: 'underline', cursor: 'pointer', color: '#038FDE' }}
            >
              cambiar
            </span>
          </h6>
        </Row>
      </div>
      <CompanySelector show={showCompanySelector} onConfirm={toggleCompanySelector} />
    </Header>
  )
}

export default Topbar
