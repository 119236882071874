import { extract, successState } from '../app/app.actions'
import { actionTypes } from './users.types'

const initialState = {
  branchUsers: [],
  password: ''
}

const usersReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.TOGGLE_ADMIN):
    case successState(actionTypes.TOGGLE_SUPERVISOR):
    case successState(actionTypes.TOGGLE_OPERATOR):
    case successState(actionTypes.GET_BRANCH_USERS):
    case successState(actionTypes.REMOVE_FROM_COMPANY):
    case successState(actionTypes.DELETE_USER):
      return {
        ...state,
        branchUsers: data
      }
    case successState(actionTypes.ADD_USER):
      return {
        ...state,
        branchUsers: extract(data.users, []),
        password: extract(data.password, '')
      }
    case successState(actionTypes.RESET_PASSWORD):
      return {
        ...state,
        password: data.password
      }
    case successState(actionTypes.CLEAR_USERS):
      return initialState
    default:
      return state || initialState
  }
}

export default usersReducer
