import React, { useCallback, useEffect, useState } from 'react'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Modal, Pagination, Row, Tooltip } from 'antd'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import BranchCell from '../../components/Branch/BranchCell'
import BranchForm from '../../components/Branch/BranchForm'
import { selectBranches } from '../../store/branches/branch.selectors'
import { actionTypes } from '../../store/branches/branch.types'
import { addBranch, searchBranches } from '../../store/branches/branch.actions'
import { clearActionResult } from '../../store/app/app.actions'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import { PlusOutlined } from '@ant-design/icons'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { GENERIC_MESSAGE_ERROR } from '../../util/messages.utils'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { getUserRoles } from '../../store/auth/auth.actions'

const { error, success } = Modal

const sortProperties = [
  { label: 'Nombre', property: 'name' }
]

const Branches = () => {
  const dispatch = useDispatch()
  const branches = useSelector(selectBranches)
  const data = branches?.items || []
  const [showModal, setShowModal] = useState(false)
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [currentBranch, setCurrentBranch] = useState(null)
  const [addBranchIsLoading, addBranchFinished] = useIsLoading([actionTypes.ADD_BRANCH])
  const [branchError, branchHasError] = useHasErrors([actionTypes.ADD_BRANCH])
  const [loading] = useIsLoading([actionTypes.GET_BRANCHES])
  const [form] = Form.useForm()
  const [searchForm] = Form.useForm()

  const refresh = useCallback(({ sortOrder, sortProperty, search } = {}) => {
    dispatch(searchBranches({
      sortOrder,
      sortProperty,
      search,
      ...pagination
    }))
  }, [dispatch, pagination])

  const toggleModal = () => {
    if (showModal) {
      setCurrentBranch(null)
    }
    setShowModal(!showModal)
  }

  const onFinish = (values) => {
    dispatch(addBranch({ ...values }))
  }

  const onPageChange = (page, pageSize) => {
    setPagination({ page, pageSize })

    searchForm.setFieldsValue()
  }

  const clickOkModal = () => {
    form.validateFields().then(() => {
      if (form.getFieldsError().filter(({ errors }) => errors.length).length > 0) {
        return
      }
      toggleModal()
      form.submit()
    })
  }
  const init = () => {
    dispatch(updateTopBarTitle('Sucursales'))
    dispatch(searchBranches({}))
    return () => dispatch(clearActionResult(actionTypes.GET_BRANCHES))
  }

  useEffect(init, [])

  useEffect(() => {
    if (addBranchFinished) {
      if (branchHasError) {
        error({
          title: '¡Uh-oh!',
          content: branchError.message || GENERIC_MESSAGE_ERROR
        })
      } else {
        success({
          title: '¡Éxito!',
          content: 'Sucursal creada exitosamente, puede empezar a operar cambiando la sucursal en el menú de arriba'
        })
        dispatch(getUserRoles())
        refresh()
      }
    }
  })

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={loading} />
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <CustomSearch
                form={searchForm}
                onChange={refresh}
                sortProperties={sortProperties}
                style={{ display: 'flex' }}
                last={false}
                dates={false}
              />
              <Row justify='end' style={{ flexGrow: 1, paddingRight: 30 }}>
                <Tooltip title='Agregar Sucursal' placement='topLeft'>
                  <Button
                    size='large'
                    className='green-button'
                    shape='circle' type='primary'
                    icon={<PlusOutlined />}
                    aria-label='add' onClick={toggleModal}
                  />
                </Tooltip>
              </Row>
            </div>
            <CustomScrollbars className='zones-scroll'>
              {
                data.length > 0 && !loading
                  ? (
                    <div>
                      {data.map((branch) => <BranchCell key={branch.id} branch={branch} />)}
                    </div>
                  )
                  : (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      No se encontraron suscursales con los filtros indicados.
                    </div>
                  )
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={branches?.total || 0}
                current={branches?.page || 0}
                pageSize={branches?.pageSize || 0}
                hideOnSinglePage={branches.total === 0}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
      <Modal
        centered
        width={1024}
        title='Agregar Sucursal'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Registrar'
            type='primary'
            onClick={() => { clickOkModal() }}
            loading={addBranchIsLoading}
          >
            Registrar
          </Button>
        ]}
      >
        <BranchForm form={form} branch={currentBranch} onFinish={onFinish} />
      </Modal>
    </div>
  )
}

export default Branches
