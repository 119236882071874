import React, { useMemo } from 'react'
import { Form, Select } from 'antd'
import { useSelector } from 'react-redux'
import { selectBranchUsers } from '../../store/users/users.selectors'
import { inputRequired } from '../../util/forms.utils'

const AssignCandidateForm = ({ form, onFinish }) => {
  const users = useSelector(selectBranchUsers)
  const usersFound = useMemo(() => users.filter(user => user.operator), [users])

  return (
    <Form
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name='userId'
        rules={[inputRequired]}
      >
        <Select
          placeholder='Seleccionar un usuario'
          optionFilterProp='children'
        >
          {
            usersFound.map(user => {
              return (
                <Select.Option key={`user_${user.id}`} value={user.id}><b>{user.name}</b> ({user.email})</Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
    </Form>
  )
}

export default AssignCandidateForm
