import { $http } from '../../util/http.utils'

class SurveyService {
  compSurvey =
    () =>
      $http.get('/surveys')

  addQuestion =
    ({ text, type, limit, options, position }) =>
      $http.post('/surveys/questions', { text, type, limit, options, position })

  singleQuestion =
    ({ id }) =>
      $http.get(`/surveys/questions/${id}`)

  updateQuestion =
    ({ id, text, type, limit, options }) =>
      $http.put(`/surveys/questions/${id}`, { text, type, limit, options })

  deleteQuestion =
    (data) =>
      $http.post('/surveys/questions/delete', data)

  addQuestionOption =
    ({ questionId, text }) =>
      $http.post(`/surveys/questions/${questionId}/options`, [text])

  deleteQuestionOption =
    ({ questionId, optionId }) =>
      $http.delete(`/surveys/questions/${questionId}/options/${optionId}`)

  updatePositions =
    (list) =>
      $http.post('/surveys/questions/positions', list)
}
export default new SurveyService()
