import React from 'react'
import { Avatar, Popover } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../store/auth/auth.selectors'
import { logout } from '../../store/auth/logout.actions'
import { truncate } from '../../util/text.utils'

const UserProfile = () => {
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  const handleLogOut = () => {
    dispatch(logout())
  }

  const userMenuOptions = (
    <ul className='gx-user-popover'>
      <Link to='/profile'>
        <li>Mi Cuenta</li>
      </Link>
      <div>
        <li onClick={handleLogOut}>Salir</li>
      </div>
    </ul>
  )

  return (

    <div className='gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row'>
      <Popover placement='bottomRight' content={userMenuOptions} trigger='click'>
        <Avatar style={{ backgroundColor: 'grey', verticalAlign: 'middle', marginRight: 10 }} size='large'>
          {user?.name[0].toUpperCase()}
        </Avatar>
        <span className='gx-avatar-name'> {truncate(user?.name, '14')}
          <i className='icon icon-chevron-down gx-fs-xxs gx-ml-2' />
        </span>
      </Popover>
    </div>
  )
}

export default UserProfile
