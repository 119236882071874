import { extract, successState } from '../app/app.actions'
import { actionTypes } from './branch.types'

const initialState = {
  branches: { items: [], page: 1, pageSize: 10 },
  current: {
    address: '',
    companyId: 0,
    contactName: '',
    email: '',
    finishedCount: 0,
    id: 0,
    inProcessCount: 0,
    lastCut: '2000-01-01T19:14:48',
    name: '',
    phone: ''
  }
}

const branchReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.GET_BRANCHES):
      return {
        ...state,
        branches: extract(data, initialState.branches)
      }
    case successState(actionTypes.ADD_BRANCH):
    case successState(actionTypes.GET_BRANCH):
      return {
        ...state,
        current: data
      }
    case successState(actionTypes.CLEAR_BRANCH):
      return initialState
    default:
      return state || initialState
  }
}

export default branchReducer
