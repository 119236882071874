import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Col, Form, Modal, Pagination, Popover, Radio, Row, Select, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectBranchUsers} from "../../store/users/users.selectors";
import {
  selectCurrentBranch,
  selectCurrentCompany,
  selectIsAdmin,
  selectIsSupervisor
} from "../../store/auth/auth.selectors";
import {updateTopBarTitle} from "../../store/settings/settings.actions";
import {getBranchUsers} from "../../store/users/users.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {actionTypes} from "../../store/assignments/assignments.types";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {useHasErrors} from "../../store/app/error/error.hooks";
import {UNEXPECTED_OOPS} from "../../util/messages.utils";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import CustomSearch from "../../components/CustomSearch/CustomSearch";
import {FilterOutlined, UserOutlined} from "@ant-design/icons";
import CustomScrollbars from "../../components/Scrolls/CustomScrollbars";
import AssignCandidateForm from "../../components/Candidate/AssignCandidateForm";
import {reassignAssignment, searchAssignment} from "../../store/assignments/assignments.actions";
import ProcessAssignmentCell from "../../components/Surveys/ProcessAssignmentCell";
import {selectAssignments} from "../../store/assignments/assignments.selector";

const { Option } = Select

const sortProperties = [
  { label: 'Folio', property: 'id' },
  { label: 'Nombre', property: 'name' },
  { label: 'Fecha de Envío', property: 'sentDate' }
]

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginRight: '30px'
}

const { error, success } = Modal

const ProcessAssignments = () => {
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm()
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [filter, setFilter] = useState('Todos')
  const [user, setUser] = useState(0)
  const assignments = useSelector(selectAssignments)
  const branchUsers = useSelector(selectBranchUsers)
  const data = assignments?.items || []
  const isSupervisor = useSelector(selectIsSupervisor)
  const isAdmin = useSelector(selectIsAdmin)
  const access = useMemo(() => isSupervisor || isAdmin, [isAdmin, isSupervisor])
  const [showAssignmentModal, setAssignmentModal] = useState(false)
  const [assignment, setAssignment] = useState(null)
  const toggleAssignmentModal = useCallback((user) => {
    setAssignment(user)
    setAssignmentModal(!showAssignmentModal)
  }, [showAssignmentModal])

  const init = () => {
    dispatch(updateTopBarTitle('Encuestas en proceso'))
    dispatch(searchAssignment({ status: 'ENVIADO' }))
    if (isSupervisor) {
      dispatch(getBranchUsers())
    }
    return () => dispatch(clearActionResult(actionTypes.SEARCH_ASSIGNMENT))
  }
  useEffect(init, [])

  const getFilters = () => {
    return (
      <Row className='gx-p-2'>
        <Col>
          <Radio.Group onChange={e => setFilter(e.target.value)} value={filter}>
            <Radio value='Todos' style={radioStyle}>Todos</Radio>
            <Radio value='Enviados' style={radioStyle}>Enviados</Radio>
            <Radio value='Investigando' style={radioStyle}>En investigación</Radio>
          </Radio.Group>
        </Col>
      </Row>
    )
  }

  const getUserFilters = () => {
    return (
      <Row className='gx-p-2'>
        <Col>
          <Select style={{ width: 200 }} showSearch optionFilterProp='children' value={user} onChange={setUser}>
            <Option value={0}>Todos</Option>
            {
              (branchUsers || []).map((user) =>
                <Option key={`users-${user.id}`} value={user.id}>{user.name}</Option>
              )
            }
          </Select>
        </Col>
      </Row>
    )
  }

  const refresh = useCallback(({ search, last, from, to, sortOrder, sortProperty } = {}) => {
    const request = { pending: true, investigating: true }
    if (filter === 'Enviados') {
      request.investigating = false
    } else if (filter === 'Investigando') {
      request.pending = false
    }

    dispatch(searchAssignment({
      status: 'ENVIADO',
      search,
      from: from?.unix(),
      to: to?.unix(),
      last,
      ...request,
      ...pagination,
      sortOrder,
      sortProperty,
      byUser: access ? user : null
    }))
  }, [access, dispatch, filter, pagination, user])

  const currentCompany = useSelector(selectCurrentCompany)
  const currentBranch = useSelector(selectCurrentBranch)
  useEffect(refresh, [currentCompany, currentBranch])

  const [formReassign] = Form.useForm()
  const handleReassign = ({ userId }) => {
    dispatch(reassignAssignment({ assignmentId: assignment.id, userId }))
  }

  const [assignIsLoading, assignFinished] = useIsLoading([actionTypes.REASSIGN_CANDIDATE])
  const [assignError, assignHasError] = useHasErrors([actionTypes.REASSIGN_CANDIDATE])
  useEffect(() => {
    if (assignFinished) {
      if (assignHasError) {
        error({
          title: '¡Uh-oh!',
          content: assignError.message || UNEXPECTED_OOPS
        })
      } else {
        success({
          title: '¡Éxito!',
          content: 'Asignado con éxito'
        })
      }
      toggleAssignmentModal(null)
      refresh()
    }
  }, [assignError.message, assignFinished, assignHasError, refresh, toggleAssignmentModal])

  const onPageChange = (page, pageSize) => setPagination({ page, pageSize })

  return (
    <div>
      <LoadingIndicator loading={assignIsLoading} />
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <CustomSearch form={searchForm} sortProperties={sortProperties} onChange={refresh} />
              <div style={{ borderLeft: '1px solid grey', height: 35, marginLeft: 30, marginRight: 5, marginTop: -15 }} />
              <Row>
                <Tooltip title='Filtrar por estado'>
                  <Popover content={getFilters()} title='Filtrar por estado' trigger='click' placement='bottomRight'>
                    <Button icon={<FilterOutlined />} />
                  </Popover>
                </Tooltip>
              </Row>
              {
                access &&
                <Row justify='end' style={{ marginLeft: 25 }}>
                  <Tooltip title='Filtrar por usuario'>
                    <Popover content={getUserFilters()} title='Filtrar por usuario' trigger='click' placement='bottomRight'>
                      <Button icon={<UserOutlined />} />
                    </Popover>
                  </Tooltip>
                </Row>
              }
              <span style={{ paddingRight: 30 }} />
            </div>
            <CustomScrollbars style={{ height: 'calc(100vh - 213px)' }}>

              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      No se encontraron encuestas con los filtros específicados
                    </div>
                  )
                  : (
                    <div>
                      {
                        data.map((assignment, index) => <ProcessAssignmentCell handleModal={toggleAssignmentModal} assignment={assignment} key={index} />)
                      }
                      <div style={{ height: '1em' }} />
                    </div>
                  )
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={assignments.total || 0}
                current={assignments.page || 0}
                pageSize={assignments.pageSize || 0}
                hideOnSinglePage={assignments.total === 0}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
      <Modal
        title={`Reasignar ${assignment?.name} al operador:`}
        visible={showAssignmentModal}
        footer={[
          <Button
            key='cancelar'
            onClick={toggleAssignmentModal}
          > Cancelar
          </Button>,
          <Button
            key='ok'
            type='primary'
            onClick={() => formReassign.submit()}
          > Aceptar
          </Button>
        ]}
      >
        <Row>
          <Col span={24}>
            <AssignCandidateForm form={formReassign} onFinish={handleReassign} />
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default ProcessAssignments
