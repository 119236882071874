import { $http } from '../../util/http.utils'

class BranchService {
  search =
    ({ active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty }) =>
      $http.get('/branch', { params: { active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty } })

  singleBranch =
    ({ id }) =>
      $http.get(`/branch/${id}`)

  updateBranch =
    ({ id, address, contactName, email, name, phone }) =>
      $http.put(`/branch/${id}`, { address, contactName, email, name, phone })

  newBranch =
    ({ id, address, contactName, email, name, phone }) =>
      $http.post('/branch', { id, address, contactName, email, name, phone })

  deleteBranch =
    ({ id }) =>
      $http.delete(`/branch/${id}`)
}

export default new BranchService()
